// GENERAL
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect, Fragment } from "react";
import getFontAwesomeIcon from "../../../../../utils/getFontAwesomeIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
// COMPONENTS
import BaseButton from "../../../../../components/ui/BaseButton";
import BaseText from "../../../../../components/ui/BaseText";
import SelectedShipmentItemsTable from "./SelectedShipmentItemsTable";
import SupplyFormGuardrail from "../../../../../components/misc/SupplyFormGuardrail";
import FulfilledShipLoadingScreen from "../../../../Shipments/listing/components/FulfilledShipLoadingScreen";

// TYPES, FIELDS, UTILS
import {
	Field,
	ShipmentData,
	UpdateShipmentFormProps,
	ShippingMethodOptions,
	ShippingCarrier,
	ShipmentOrderSelectedItemTypes,
	CarrierAccount,
	ModifiedSerialNumbersOptionsArray,
} from "../../types";
import dummyUpdateShipmentFields from "./DUMMYUPDATESHIPMENTFIELDS.json";
import {
	fulfillShipment,
	handleShipmentDownload,
	saveShipment,
} from "../../../api/UpdateShipmentApi";
import {
	checkDimensions,
	formatShipmentData,
	getUpdateShipmentFormOptions,
	renderUpdateShipmentFormInput,
} from "./utils/";
import { useToaster } from "../../../../../hooks/useToasterContext";

const sectionClassesMap: { [key: string]: string } = {
	col1: "w-56 min-w-56 max-1893px:w-150px max-1893px:min-w-150px",
	col2: "w-16 min-w-16",
	col3: "w-24 min-w-24",
	col4: "w-150px min-w-150px",
	col5: "grid grid-cols-4 w-80 min-w-80 gap-x-3 max-1893px:w-300px max-1893px:min-w-300px",
	col6: "w-44 min-w-44 max-1893px:w-120px max-1893px:min-w-120px",
	col7: "w-48 min-w-48  max-1893px:w-36 max-1893px:min-w-36",
	col8: "w-56 min-w-56 grid grid-rows-3 items-start h-1/2 gap-x-4 max-1893px:w-56 max-1893px:min-w-56",
};

const UpdateShipmentForm: React.FC<UpdateShipmentFormProps> = ({
	formClasses,
	optionsArray,
	shipmentAddress,
	shipmentNumber,
}) => {
	let { addressee, state, line1, line2, city, zip, attention, uuid } =
		shipmentAddress;
	const [isGuardrailOpen, setIsGuardrailOpen] = useState(false);
	const { addToaster } = useToaster();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const internalId = queryParams.get("internalId");

	const [isFulfillAndShipLoading, setIsFulfillAndShipLoading] =
		useState(false);
	const [isSavingShipmentLoading, setIsSavingShipmentLoading] =
		useState(false);
	const [carrierAccountNumber, setCarrierAccountNumber] = useState<
		CarrierAccount[]
	>([]);
	const [shippingMethodOptions, setShippingMethodOptions] = useState<
		ShippingMethodOptions[] | null
	>(null);

	const shipmentsUrl = `/shipments?internalId=${internalId}`;
	const shipmentsItemsUrl = `/shipment-items?internalId=${internalId}`;
	const navigate = useNavigate();
	const [selectedItemsData, setSelectedItemsData] = useState<
		ShipmentOrderSelectedItemTypes[]
	>([]);

	/////////////////////////////////////////// FETCH DATA ///////////////////////////////////////////
	useEffect(() => {
		if (selectedItemsData) {
			const nonSerialized = selectedItemsData.filter((item) => {
				return item.itemData.item.inventoryType === "NON_SERIALIZED";
			});
			if (nonSerialized.length > 0) {
				const nonSerializedObject = nonSerialized.reduce(
					(acc, item) => {
						if (item.partNumber !== null) {
							acc[item.partNumber] = "";
						}
						return acc;
					},
					{} as { [key: string]: string }
				);

				Object.keys(nonSerializedObject).forEach((key) => {
					setValue(`partNumber: ${key}` as any, "");
				});
			}
		}
	}, [selectedItemsData]);

	useEffect(() => {
		const storedItems = localStorage.getItem("selectedItems");
		if (storedItems) {
			setSelectedItemsData(JSON.parse(storedItems));
		}

		if (optionsArray && optionsArray.length > 0) {
			const shippingCarriers = optionsArray[0].shippingCarriers;
			if (shippingCarriers) {
				let shipmentMethods = shippingCarriers.map(
					(carrier: ShippingCarrier) => {
						// TODO: Remove option without name from shippingMethods
						let shippingMethods = carrier.shippingMethods.filter(
							(method) => {
								return method.name !== "";
							}
						);
						return {
							carrierName: carrier.name,
							shippingMethods: shippingMethods,
						};
					}
				);

				let carrierAccountNumbers = shippingCarriers.map(
					(carrier: ShippingCarrier) => {
						return {
							carrierName: carrier.name,
							accountNumbers:
								carrier.shippingCarrierAccountNumbers.map(
									(account) => {
										return {
											uuid: account.uuid,
											name: `${carrier.name} - ${account.accountNumber}`,
										};
									}
								),
						};
					}
				);

				setCarrierAccountNumber(carrierAccountNumbers);
				setShippingMethodOptions(shipmentMethods);
			}
		}
	}, [optionsArray]);

	////////////////////////////////////////////// STYLES ////////////////////////////////////////////
	const updateFormClasses = `flex w-full space-x-3.5 bg-white p-4 mb-5 rounded-xl`;
	const updateFormIcon = `p-1.5 text-2xl mr-22px text-navy-270`;
	const buttonContainerClasses = `flex justify-end w-full max-lg:flex-col mt-12 max-md:justify-between max-md:items-end`;
	const guardrailBackgroundClasses = `fixed top-0 left-0 h-screen w-screen bg-black opacity-30 z-50`;
	const loadingScreenBackgroundClasses = `fixed top-0 left-0 h-screen w-screen z-50`;

	/////////////////////////////////////////// FORM SETUP ///////////////////////////////////////////

	const formMethods = useForm<ShipmentData>({
		mode: "all",
		defaultValues: {
			state: state,
			addressee: addressee,
			attention: attention,
			line1: line1,
			line2: line2,
			city: city,
			zip: zip,
			weight: "",
			signatureType: "Not Required",
			country: {
				name: "United States",
				uuid: "0bc27f7a-f17f-2312-f942-8c453bc815c3",
			},
			shipmentMethod: {
				name: "Ground",
				uuid: "410e9aa4-cf9d-7fb9-7c14-66bf5cf2debe",
			},
			name: `Shipment ${shipmentNumber}`,
		},
		reValidateMode: "onSubmit",
	});

	const {
		handleSubmit,
		control,
		watch,
		getValues,
		setError,
		clearErrors,
		setValue,
		formState: { errors },
	} = formMethods;
	const watchedFields = watch(["carrier", "shipmentMethod"]);
	const formValues = getValues();

	const getValueFromPath = (
		data: { [key: string]: any },
		valueKey: string
	): any => {
		return valueKey
			.split(".")
			.reduce(
				(accum: any, currentValue: any) =>
					accum ? accum[currentValue] : undefined,
				data
			);
	};

	// invoked when user deletes an item from the serial number modal, updates the form data and local storage
	const updateItemQuantity = (partNumber: string) => {
		const storedItems = localStorage.getItem("selectedItems");
		if (storedItems) {
			let items = JSON.parse(storedItems);

			let item = items.find(
				(item: ShipmentOrderSelectedItemTypes) =>
					item.partNumber === partNumber
			);

			if (item && item.quantity > 0) {
				item.quantity = (parseInt(item.quantity) - 1).toString();

				localStorage.setItem("selectedItems", JSON.stringify(items));
				setSelectedItemsData(items);
			}
		}
	};

	/////////////////////////////////////////// SUBMIT DATA ///////////////////////////////////////////
	const onFormSubmit = async (
		data: { [key: string]: any },
		formAction: string
	) => {
		setIsGuardrailOpen(false);
		// manually check for errors on required fields
		dummyUpdateShipmentFields.DUMMYUPDATESHIPMENTFIELDS?.forEach(
			(fieldSection) => {
				fieldSection.fields.forEach((field: { [key: string]: any }) => {
					let value = getValues(field.valueKey);

					if (field.isRequired && !value) {
						setError(field.valueKey, {
							type: "manual",
							message: "Required field.",
						});
					} else {
						clearErrors(field.valueKey);
					}
				});
			}
		);

		if (!data.weight) {
			setError("weight", {
				type: "manual",
				message: "Required field.",
			});
		} else {
			clearErrors("weight");
		}

		checkDimensions(data, setError, clearErrors);

		// format data for payload
		const newData = formatShipmentData(
			data,
			internalId as string,
			selectedItemsData,
			uuid,
			setError,
			clearErrors
		);

		// Check for errors after all validations
		let hasErrors = Object.keys(errors).length > 0;
		if (hasErrors) {
			setIsFulfillAndShipLoading(false);
			return; // Stop form submission if there are errors
		}

		if (formAction === "fulfillAndShip" && !hasErrors) {
			setIsFulfillAndShipLoading(true);
			const response = await saveShipment(newData);

			if (response?.status === 201) {
				const shipmentResponse = await fulfillShipment(
					response?.itemFulfillmentUuid
				);
				addToaster({
					isOpen: true,
					message: "Order fulfilled and shipped successfully",
					status: "success",
				});

				// Call the function to download the PDF
				await handleShipmentDownload(shipmentResponse);

				navigate(shipmentsUrl);
			} else {
 				addToaster({
					isOpen: true,
					message: "Error fulfilling shipment",
					status: "error",
				});
			}
		} else if (formAction === "delete" && !hasErrors) {
			// Perform delete action
			navigate(shipmentsUrl);
		} else if (formAction === "save" && !hasErrors) {
			setIsSavingShipmentLoading(true);

			const response = await saveShipment(newData);
			if (response?.status === 201) {
				navigate(shipmentsUrl);
				addToaster({
					isOpen: true,
					message: "Saved successfully",
					status: "success",
				});
			} else {
 				addToaster({
					isOpen: true,
					message: "Error saving shipment",
					status: "error",
				});
			}
		} else if (formAction === "saveAndCreateNew") {
			setIsFulfillAndShipLoading(true);
			const response = await saveShipment(newData);
			if (response?.status === 201) {
				navigate(shipmentsItemsUrl);
			} else {
 				addToaster({
					isOpen: true,
					message: "Error saving shipment",
					status: "error",
				});
			}
		}
	};
	const handleFulfillAndShip = () => {
		onFormSubmit(getValues(), "fulfillAndShip");
	};
	const openGuardrail = () => {
		setIsGuardrailOpen(true);
	};

	// renders the correct loading message/screen based on submit action (fulfill and ship vs. save)
	const renderLoadingScreen = (
		isLoading: boolean,
		loadingMessage: string
	) => {
		return isLoading ? (
			<>
				<div className={loadingScreenBackgroundClasses}>
					{""}
					<FulfilledShipLoadingScreen
						containerClasses="flex flex-col items-center justify-center bg-white h-full w-full"
						loadingMessage={loadingMessage}
					/>
				</div>
			</>
		) : null;
	};

	/////////////////////////////////////////// FORMAT CONTENT ////////////////////////////////////////

	// Memoized content for form fields at the top of the update shipment page
	const formContent = React.useMemo(() => {
		return dummyUpdateShipmentFields.DUMMYUPDATESHIPMENTFIELDS?.map(
			(fieldSection: { [key: string]: any }, index: number) => {
				const columnFields = fieldSection.fields;
				const sectionTitle = fieldSection.sectionTitle;
				const sectionClasses =
					sectionClassesMap[fieldSection.sectionClasses];

				return (
					optionsArray &&
					shipmentNumber && (
						<div
							key={`${index}-${""}`}
							className={`${sectionClasses}`}
						>
							{sectionTitle && (
								<div className="flex items-center w-full">
									<BaseText
										text={sectionTitle}
										size="text-xs"
										color="text-navy-350"
										tag="h3"
										fontFamily="font-omnes-medium"
										additionalClasses="uppercase text-left pt-1 text-nowrap"
									/>
								</div>
							)}
							{columnFields.map((field: Field) => {
								let options;
								let value = getValueFromPath(
									shipmentAddress,
									field.valueKey
								);

								if (field.valueKey === "state.name") {
									value =
										shipmentAddress?.address?.state?.name;
								}

								if (shippingMethodOptions) {
									options = getUpdateShipmentFormOptions(
										field,
										optionsArray,
										watchedFields,
										shippingMethodOptions,
										carrierAccountNumber
									);
								}
								if (field.valueKey === "itemQuantity") {
									value = selectedItemsData.length;
								}

								return (
									<Fragment key={field.id}>
										{renderUpdateShipmentFormInput(
											field,
											value,
											options,
											selectedItemsData,
											shipmentAddress,
											watchedFields,
											control,
											setError,
											clearErrors
										)}
									</Fragment>
								);
							})}
						</div>
					)
				);
			}
		);
	}, [
		dummyUpdateShipmentFields,
		optionsArray,
		watchedFields,
		shipmentAddress,
		carrierAccountNumber,
		shipmentNumber,
		formValues,
	]);

	//////////////////////////////////////////////////////////////////////////////////////////////////

	return optionsArray && selectedItemsData ? (
		<>
			<AnimatePresence>
				<motion.div
					className="w-full"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: "easeOut",
						duration: 0.3,
					}}
				>
					<FormProvider {...formMethods}>
						<form
							className={formClasses}
							onSubmit={handleSubmit((data) =>
								onFormSubmit(data, "submit")
							)}
						>
							<div className={updateFormClasses}>
								<div className={updateFormIcon}>
									{getFontAwesomeIcon("boxTaped", "solid")}
								</div>
								{formContent}
							</div>
							<SelectedShipmentItemsTable
								data={selectedItemsData}
								control={control}
								updateQuantity={updateItemQuantity}
							/>
						</form>
					</FormProvider>
					<div className={buttonContainerClasses}>
						<BaseButton
							text="Save & Create New Shipment"
							hoverBackground="hover:bg-primaryHoverLight"
							fontColor="text-primary"
							borderColor="border-1 border-primary"
							additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4"
							shape="rounded-full"
							as="button"
							fontFamily="font-omnes-medium"
							buttonSize="h-7 w-56"
							icon={getFontAwesomeIcon("plus", "regular")}
							iconClasses="pr-2 text-xs text-primary"
							onClick={() => {
								onFormSubmit(getValues(), "saveAndCreateNew");
							}}
							fontSize="text-sm"
						/>
						<BaseButton
							text="Fulfill & Ship"
							hoverBackground="hover:bg-primaryHoverLight"
							fontColor="text-primary"
							borderColor="border-1 border-primary"
							additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4"
							shape="rounded-full"
							as="button"
							fontFamily="font-omnes-medium"
							buttonSize="h-7 w-28"
							onClick={openGuardrail}
							fontSize="text-sm"
						/>
						<BaseButton
							text="Save"
							bgColor="bg-primary"
							hoverBackground="hover:bg-primaryHover"
							fontColor="text-white"
							additionalClasses="py-1 px-3 max-md:mr-2.5"
							shape="rounded-full"
							as="button"
							fontFamily="font-omnes-medium"
							buttonSize="h-7 w-28"
							onClick={() => {
								onFormSubmit(getValues(), "save");
							}}
							fontSize="text-sm"
						/>
					</div>
				</motion.div>
			</AnimatePresence>
			{isGuardrailOpen && (
				<>
					<div className={guardrailBackgroundClasses}>{""}</div>
					<SupplyFormGuardrail
						messages={{
							messageOne: `Fulfill and ship this shipment?`,
							messageTwo:
								"This will send the shipment to the carrier for label generation and printing.",
							isOpen: true,
						}}
						onSave={() => {
							handleFulfillAndShip();
						}}
						onCancel={() => setIsGuardrailOpen(false)}
						buttonText={{
							leftButtonText: "Don't Save",
							centerButtonText: "Cancel",
							rightButtonText: "Fulfill & Ship",
						}}
					/>
				</>
			)}
			{renderLoadingScreen(
				isFulfillAndShipLoading,
				"Fulfilling and shipping shipments..."
			)}

			{renderLoadingScreen(isSavingShipmentLoading, "Saving shipment...")}
		</>
	) : (
		<></>
	);
};

export default UpdateShipmentForm;
