// GENERAL
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

// HOOKS
import useHostName from "../../../../../hooks/useHostName";
import usePageDetails from "../../../../PageHooks/usePageDetails";
import { getOrderDetails } from "../../..//listing/api/OrdersApi";
import { useItemsContext } from "../../../../../hooks/dataTableHooks/useItemsContext";
import { useUrl } from "../../../../../hooks";
import {
	fadeInOutVariants,
	slideOutMenuOverlayVariant,
	slideOutMenuVariants,
} from "../../../../../motionVariants";
import usePrivilegeCheck from "../../../../../hooks/usePrivilegeCheck";
import { useToaster } from "../../../../../hooks/useToasterContext";

// COMPONENTS
import Container from "../../../../../components/layout/Container";
import Section from "../../../../../components/layout/Section";
import { LoadingMessage, SupplyPortal } from "../../../../../components/misc";
import Toaster from "../../../../../components/misc/Toaster";
import { FulfillmentModal, OrderShipment, OrderTransmissionActivity } from "..";
import BasePill from "../../../../../components/ui/BasePill";
import StatusColorDot from "../../../../../components/ui/Tables/StatusColorDot";
import BaseButton from "../../../../../components/ui/BaseButton";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import dummyOrderDetailsFields from "./../../modals/OrderView/ORDERDETAILSFIELDS.json";
import OrderContentSection from "./../../modals/OrderView/OrderContentSection";
import BaseText from "../../../../../components/ui/BaseText";
import OrderInvoiceList from "./../../modals/OrderView/OrderInvoiceList";
import BaseContactInfo from "../../../../../components/ui/BaseContactInfo";
import BaseAddress from "../../../../../components/ui/BaseAddress";
import { BaseToolTip, EventLog } from "../../../../../components/ui";
import OrderItemsTable from "../../tables/OrderItemsTable";
import AddNotesForm from "../../../../../components/ui/AddNotes/AddNotesForm";
import BaseModal from "../../../../../components/layout/Modal/BaseModal";

// UTILS
import formatCurrency from "../../../../../utils/formatCurrency";
import { getSalesOrderSideBar } from "../../../listing/api/OrdersApi";
import { getFontAwesomeIcon } from "../../../../../utils";
import formatDate from "../../../../../utils/formatDateForDetailsBox";

// TYPES
import { OrderViewProps, Shipments, OrderDetails } from "./../../types";

const modalVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
	exit: { opacity: 0 },
};

const OrderViewModalContent: React.FC<OrderViewProps> = ({
	modalRef,
	borderColor = "primary",
	hasBorder = false,
	handleClose,
}) => {
	const { isAdmin } = usePrivilegeCheck();
	const { activeUuid } = useItemsContext();
	const naMessage = "—";
	const location = useLocation();
	const [openProducts, setOpenProducts] = useState<string[]>([]);
	const [lastCopiedTrackingNumber, setLastCopiedTrackingNumber] =
		useState<string>("");
	const prevURL: string = location.state?.prevURL;
	const prevUUID: string = location.state?.prevUUID;
	const { setPreviousUrl, setPreviousItemUuid, previousItemUuid } = useUrl(
		prevURL,
		""
	);
	const { addToaster } = useToaster();
	const [hasShownError, setHasShownError] = useState(false);

	const [orderBoxDetails, setOrderBoxDetails] = useState<
		OrderDetails | null | undefined
	>();
	const { uuid } = useParams<{ uuid: string }>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

	const [isEventLogOpen, setIsEventLogOpen] = useState<boolean>(false);
	const [isViewAllNotesOpen, setIsViewAllNotesOpen] =
		useState<boolean>(false);
	const [refetchData, setRefetchData] = useState<boolean>(false);

	const toggleViewAllNotes = () => {
		setIsViewAllNotesOpen(!isViewAllNotesOpen);
	};

	const toggleMenu = () => {
		setIsEventLogOpen(!isEventLogOpen);
	};

	const handleBackgroundClick = () => {
		setIsViewAllNotesOpen(false);
	};

	useEffect(() => {
		if (setPreviousUrl) {
			setPreviousUrl(prevURL);
		}
	}, [setPreviousUrl, prevURL]);

	useEffect(() => {
		setHasShownError(false);
	}, [activeUuid]);

	useEffect(() => {
		if (setPreviousItemUuid) {
			setPreviousItemUuid(uuid || "");
		}
	}, [setPreviousItemUuid, prevUUID]);

	const hostName = useHostName().hostName;
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const { data, labels, isLoading, isError, error } = usePageDetails(
		activeUuid || "",
		"sales-orders-view",
		getOrderDetails,
		refetchData
	);

	////////////////////////////////////////////////////////////////// FIXME: /////////////////////////////////////////
	// ADDED USE EFFECT TO FETCH ORDER BOX DETAILS FOR SHIP TO, BILL TO
	// NEED TO REFACTOR SO THIS DATA COMES IN WITH THE usePageDetails, test comment

	useEffect(() => {
		const fetchOrderBoxDetails = async () => {
			const id = previousItemUuid || activeUuid;
			if (id) {
				setIsLoadingState(true);
				const orderBoxDetails = await getSalesOrderSideBar(id);
				setOrderBoxDetails(orderBoxDetails?.salesOrder);
				setIsLoadingState(false);
			}
		};

		fetchOrderBoxDetails();
	}, [activeUuid, previousItemUuid]);

	useEffect(() => {
		if (data && hostName === "COMPASS" && !isError) {
			const itemDetails = data?.items;
		}
	}, [activeUuid, data]);

	useEffect(() => {
		if (!hasShownError) {
			if (
				hostName === "COMPASS" &&
				isError &&
				error &&
				error.errorCode === 403 &&
				handleClose &&
				!isAdmin
			) {
				addToaster({
					isOpen: true,
					message:
						"Restricted Record. You don't have access to this record.",
					status: "warning",
				});
				handleClose();

				setHasShownError(true);
			} else if (isError && error && !isAdmin) {
				// Handle other errors
				console.error("An error occurred:", error.errorMessage);
				addToaster({
					isOpen: true,
					message: "An error occurred. Please try again later.",
					status: "error",
				});
				handleClose && handleClose();
				setHasShownError(true);
			}
		}
	}, [isError, error, hostName, isAdmin, handleClose, hasShownError]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handleModalClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
	};

	const textEndHeaders: number[] = [0, 6, 7, 8, 9, 10];

	if (isLoading || data === null || isLoadingState)
		return (
			<Container
				containerClasses="container mx-auto px-4"
				additionalClasses="flex justify-center items-center"
			>
				<Section
					additionalClasses="min-full min-h-dvh
				 flex justify-center items-center"
				>
					<LoadingMessage message="" />
				</Section>
			</Container>
		);

	if (isError)
		return (
			<Container
				containerClasses="container mx-auto px-4"
				additionalClasses="flex justify-center items-center"
			>
				<Toaster
					color="red-500"
					message="There was an error loading the order details."
					additionalClasses="mt-20 w-1/2 mx-auto text-center "
				/>
			</Container>
		);

	if (!data)
		return (
			<Container>
				<Toaster
					color="red-500"
					message="There was an error loading the order details."
					additionalClasses="mt-20 w-1/2 mx-auto text-center"
				/>
			</Container>
		);

	const toggleProduct = (id: string) => {
		if (openProducts.includes(id)) {
			setOpenProducts(
				openProducts.filter((productId) => productId !== id)
			);
		} else {
			setOpenProducts([...openProducts, id]);
		}
	};

	const fulfillmentModal = (
		<FulfillmentModal
			isModalOpen={isModalOpen}
			toggleModal={toggleModal}
			selectedTrackingNumber={""}
			products={data}
			toggleProduct={toggleProduct}
			openProducts={openProducts}
		/>
	);

	const baseSalesOrderPathPath = data.salesOrder;
	const priority = baseSalesOrderPathPath?.urgency?.name || naMessage;
	const submittedBy =
		baseSalesOrderPathPath?.createdByUser?._name || naMessage;
	const notifications =
		baseSalesOrderPathPath?.salesOrderEmailAddresses || naMessage;

	const salesOrderNotes = data?.salesOrder?.salesOrderNotes
		?.slice()
		.reverse();
	const dateOrder = formatDate(data?.salesOrder?.dateOrder);
	const subTotal = baseSalesOrderPathPath?._subtotalPurchase;
	const orderTotalLease = formatCurrency(
		baseSalesOrderPathPath?._totalLease || "0"
	);
	const tax = formatCurrency(baseSalesOrderPathPath?.tax || 0);
	const total = formatCurrency(baseSalesOrderPathPath?._totalPurchase || 0);
	const shipping = formatCurrency(baseSalesOrderPathPath?.shipping || 0);
	const formattedSubTotal = formatCurrency(subTotal || 0);
	const invoiceData = data.invoices;
	const orderNumber =
		hostName === "PRUDENTIAL"
			? data?.salesOrder?.number
			: data?.salesOrder?.number;
	// @ts-ignore
	const orderStatus = data.salesOrder?._status || naMessage;
	const orderDescriptionForSummary =
		data.salesOrder?.description || naMessage;
	const customerPurchaseOrder =
		data.salesOrder?.customerPurchaseOrder || naMessage;
	const shouldRenderDetails =
		submittedBy !== "—" || priority !== "—" || notifications.length > 0;

	const costCenter = data.salesOrder?._costCenter || naMessage;

	const salesOrderNotesNotAdmin = salesOrderNotes?.filter(
		(note: any) => note?.salesOrderNoteType.slug !== "admin"
	);

	const adminNotes = salesOrderNotes?.filter(
		(note: any) => note?.salesOrderNoteType.slug === "admin"
	);
	////////////////////////////////////////////////////////////////// FIXME: /////////////////////////////////////////////////////////////////////
	// FIXME: this is formatting the data for each column because the data is coming from two separate api calls
	const orderSummary = {
		subTotal: formattedSubTotal,
		shipping: shipping,
		tax: tax,
		total: total,
		_totalLease: orderTotalLease,
	};

	// FIXME: this is formatting the data for each column because the data is coming from two separate api calls
	const orderDetails = {
		orderDescription: orderDescriptionForSummary,
		customerPurchaseOrder: customerPurchaseOrder,
		orderBoxDetails: orderBoxDetails,
		costCenter: costCenter,
	};

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// CLASSES:
	const overflowClass = isEventLogOpen
		? "overflow-hidden"
		: "overflow-scroll min-h-[95dvh]";
	const modalBorderClassNames = `${overflowClass} bg-white min-w-wideModal rounded-3xl border-2  pt-0 ${
		hasBorder ? `border-${borderColor}` : "border-transparent"
	}`;

	// HEADER CLASSES:
	const headerContainerClassNames =
		"flex flex-col items-between justify-between sticky top-[-5px] bg-white z-20 max-lg:flex-col min-h-[62px] pb-8";
	const headerTitleAndButtonsContainerClassNames =
		"flex items-start max-lg:flex-col justify-between w-full pb-2";
	const headerTitlesContainerClassNames =
		"flex w-2/3 max-lg:w-full max-lg:ml-1";
	const headerButtonContainerClassNames =
		"flex w-1/3 justify-end  max-lg:pt-3 max-lg:justify-start";
	const mobileExitButtonClassNames =
		"hidden max-lg:flex w-full justify-end py-2";

	// ORDER DETAILS CLASSES:
	const orderDetailsSectionClasses =
		"flex pb-5 max-xl:grid gap-5 max-xl:grid-cols-2 max-xl:gap-8 max-md:grid-cols-1 max-md:gap-2";
	const orderDetailsBaseContainerClasses =
		"flex flex-col items-start w-[25%] grow max-xl:w-[90%] py-2.5 px-3 max-xl:mx-6 max-xl:my-6 max-sm:mx-0";
	const shipToBaseContainerClasses =
		"flex flex-col items-start w-1/5 grow max-xl:w-[90%] py-2.5 px-3 max-xl:mx-6 max-xl:my-6 max-sm:mx-0";
	const billToBaseContainerClasses =
		"flex flex-col items-start w-1/5 grow max-xl:w-[90%] py-2.5 px-3 max-xl:mx-6 max-xl:my-6 max-sm:mx-0";
	const orderSummaryBaseContainerClasses =
		"flex flex-col items-start w-[25%] grow max-xl:w-[90%] py-2.5 px-3 max-xl:mx-6 max-xl:my-2 max-sm:mx-0";
	const individualSectionContainerClasses =
		"flex flex-col items-start w-full h-full";
	// TABLE CLASSES:
	const tableHeaderContainerClasses = `flex justify-start items-center ${
		hostName === "PRUDENTIAL" ? "pb-2" : ""
	}`;
	// INVOICES AND SHIPMENTS CLASSES:
	const invoiceAndShipmentSectionClasses =
		"flex w-full justify-between mt-[26px] max-xl:flex-col gap-3";
	const invoicesBaseContainerClasses =
		"flex flex-col items-start w-full max-xl:w-full mb-30 py-3 px-4 max-xl:mb-6 min-h-72 border-transparent";
	const shipmentsBaseContainerClasses =
		"flex flex-col items-start w-full max-xl:w-full mb-30 py-3 px-4 max-xl:mb-6 min-h-72  border-transparent";
	const shipmentsGridClasses =
		"grid grid-cols-3 gap-2 w-full py-3 max-lg:grid-cols-1";

	// SLIDEOUT MENU CLASSES:
	const slideOutMenuClassNames = `slide-out-menu bg-white w-1/2 min-w-[300px] max-w-[500px] absolute top-0 left-0 z-40 z-[9999]`;
	const overlayClassNames = `bg-black absolute z-1 w-full left-0 top-0 cursor-pointer z-[9989]`;
	// const overlayHeight = modalRef.current
	// 	? modalRef.current.scrollHeight + "px"
	// 	: "100%";
	const overlayHeight = "100%";
	const menuItemClassNames = "sticky min-h-full max-h-full mb-20";

	const eventLogTitle = "View Log";

	return (
		<>
			<div
				ref={modalRef}
				className={modalBorderClassNames}
				onClick={handleModalClick}
			>
				<div className="px-10 py-8 max-sm:mx-8">
					<motion.header
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						aria-label="order details modal header."
					>
						<header
							className={headerContainerClassNames}
							aria-label="Order Details modal header."
						>
							<div className={mobileExitButtonClassNames}>
								{
									<>
										{(hostName === "COMPASS" && isAdmin) ||
										hostName !== "COMPASS" ? (
											<BaseButton
												text="View Log"
												bgColor="bg-white"
												borderColor="border-1 border-primary"
												hoverBackground="hover:bg-primaryHoverLight"
												additionalClasses={`text-nowrap py-1 px-3 mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
												shape="rounded-full"
												iconClasses="pr-2 max-sm:pr-0  text-primary"
												icon={getFontAwesomeIcon(
													"clock",
													"regular"
												)}
												iconBreakpoint="max-lg:pr-0"
												as="button"
												textBreakpoint="max-lg:hidden"
												onClick={toggleMenu}
												fontFamily="font-omnes-medium"
											/>
										) : null}
										<BaseToolTip
											message="Open in New Tab"
											toolTipPlacement="top"
											children={
												<BaseButton
													text=""
													bgColor="bg-white"
													additionalClasses={`p-2 mx-4px`}
													shape="rounded-full"
													iconAriaLabel="Close"
													icon={getFontAwesomeIcon(
														"arrowUpRightFromSquare",
														"regular"
													)}
													as="button"
													onClick={() =>
														window.open(
															window.location
																.href,
															"_blank"
														)
													}
													iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
												/>
											}
										/>
										<BaseToolTip
											message="Close"
											toolTipPlacement="top"
											children={
												<BaseButton
													text=""
													bgColor="bg-white"
													additionalClasses={`p-2 mx-4px`}
													shape="rounded-full"
													iconAriaLabel="Close"
													icon={getFontAwesomeIcon(
														"Xmark",
														"regular"
													)}
													as="button"
													onClick={handleClose}
													iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
												/>
											}
										/>
									</>
								}
							</div>
							<div className="flex flex-col">
								<div
									className={
										headerTitleAndButtonsContainerClassNames
									}
								>
									<div
										className={
											headerTitlesContainerClassNames
										}
									>
										{
											<BasePill
												text={
													<BaseText
														size={"text-sm"}
														color={"text-navy-500"}
														text={"Order"}
														fontFamily={
															"font-omnes-medium"
														}
														tag={"h1"}
													/>
												}
												firstIcon={getFontAwesomeIcon(
													"calculator",
													"solid"
												)}
												hoverBackgroundColor="none"
												additionalClasses="px-2.5 py-1 h-26px mr-1.5"
												shape="rounded-full"
												color="bg-supply-blue-50"
												borderColor="transparent"
												type={"span"}
												hasMobileStyle={false}
											/>
										}
										<div className="flex items-center">
											<div className="flex items-center font-omnes-regular">
												<h2 className="flex items-center text-sm mt-0">
													<span className="text-navy-350 pr-.5">
														Order Date:
													</span>
													<span className="text-navy-500 pl-1 mr-3">
														{dateOrder}
													</span>
												</h2>
												<StatusColorDot
													status={orderStatus}
													additionalClasses="flex items-center font-omnes-regular"
													useConvertedStatus={true}
													fontFamily="font-omnes-regular text-sm"
												/>
											</div>
										</div>
									</div>
									<div
										className={
											headerButtonContainerClassNames
										}
									>
										<div className="flex max-lg:hidden items-center">
											{(hostName === "COMPASS" &&
												isAdmin) ||
											hostName !== "COMPASS" ? (
												<BaseButton
													text="View Log"
													bgColor="bg-white"
													hoverBackground="hover:bg-primaryHoverLight"
													fontColor="text-primary"
													borderColor="border-1 border-primary"
													additionalClasses={`text-nowrap py-1 px-3 mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
													shape="rounded-full"
													iconClasses="pr-2 max-sm:pr-0 text-primary"
													icon={getFontAwesomeIcon(
														"clock",
														"regular"
													)}
													as="button"
													textBreakpoint=""
													onClick={toggleMenu}
													fontFamily="font-omnes-medium"
												/>
											) : null}
											<BaseToolTip
												message="Open in New Tab"
												toolTipPlacement="top"
												children={
													<BaseButton
														bgColor="bg-white"
														additionalClasses={`p-2 mx-4px`}
														iconAriaLabel="Close"
														icon={getFontAwesomeIcon(
															"arrowUpRightFromSquare",
															"regular"
														)}
														as="button"
														onClick={() =>
															window.open(
																window.location
																	.href,
																"_blank"
															)
														}
														iconClasses="flex items-center text-lg pr-0 size-3 hover:text-navy-500 text-navy-300"
													/>
												}
											/>

											<BaseToolTip
												message="Close"
												toolTipPlacement="top"
												children={
													<BaseButton
														text=""
														bgColor="bg-white"
														additionalClasses={`p-2 mx-4px`}
														shape="rounded-full"
														iconAriaLabel="Close"
														icon={getFontAwesomeIcon(
															"Xmark",
															"regular"
														)}
														as="button"
														onClick={handleClose}
														iconClasses="flex items-center text-lg pr-0 size-3 hover:text-navy-500 text-navy-300"
													/>
												}
											/>
										</div>
									</div>
								</div>
								<div className="flex items-start">
									<BaseText
										size={"text-22px"}
										color={"text-navy-500"}
										text={`Order ${orderNumber}`}
										fontFamily={"font-omnes-medium"}
										tag={"h1"}
									/>
								</div>
							</div>
						</header>
					</motion.header>
					<motion.div {...fadeInOutVariants}></motion.div>
					<section className={orderDetailsSectionClasses}>
						<BaseContainer
							columnContainerClasses={
								orderDetailsBaseContainerClasses
							}
							columnShape="semi-rounded"
							borderColor="navy-200"
							backgroundColor="bg-navy-50"
							isDisabled={false}
							children={
								<>
									<OrderContentSection
										data={orderDetails}
										hostName={hostName}
										sectionContainerClasses="flex flex-col items-start h-full w-full"
										detailsContainerClasses="w-full pt-3"
										dataLabel="ORDERDETAILS"
										fields={
											dummyOrderDetailsFields
												.DUMMYORDERDETAILSFIELDS[0]
												.ORDERDETAILS
										}
										isDisabled={false}
										valueType={"detail"}
										sectionTitle={
											<BaseText
												size={"text-sm"}
												color={"text-navy-500"}
												text={"Order Details"}
												fontFamily={"font-omnes-medium"}
												tag={"h2"}
											/>
										}
									/>
								</>
							}
						/>
						<BaseContainer
							columnContainerClasses={shipToBaseContainerClasses}
							columnShape="semi-rounded"
							borderColor="navy-200"
							backgroundColor="bg-navy-50"
							isDisabled={false}
							children={
								<>
									<OrderContentSection
										sectionContainerClasses="flex flex-col items-start"
										detailsContainerClasses="w-full pt-3"
										hostName={hostName}
										sectionTitle={
											<BaseText
												size={"text-sm"}
												color={"text-navy-500"}
												text={"Ship To"}
												fontFamily={"font-omnes-medium"}
												tag={"h2"}
											/>
										}
										children={
											<>
												<BaseContactInfo
													contactData={
														orderBoxDetails?.contact
													}
												/>
												<BaseAddress
													title={
														<BaseText
															text={"Location"}
															size={"text-sm"}
															color={
																"text-navy-500"
															}
															tag={"h2"}
															fontFamily={
																"font-omnes-medium"
															}
														/>
													}
													addressLines={[
														orderBoxDetails
															?.shipToAddress
															?.addressee ?? "",
														`Attn: ${orderBoxDetails?.shipToAddress?.attention}`,
														orderBoxDetails
															?.shipToAddress
															?.line1 ?? "",
														orderBoxDetails
															?.shipToAddress
															?.line2 ?? "",
														`${
															orderBoxDetails
																?.shipToAddress
																?.city ?? ""
														}, ${
															orderBoxDetails
																?.shipToAddress
																?.state?.code ??
															""
														} ${
															orderBoxDetails
																?.shipToAddress
																?.zip ?? ""
														}`,
														orderBoxDetails
															?.shipToAddress
															?.phoneNumber ?? "",
													]}
													additionalClasses="pt-3"
												/>
												<div className="text-sm text-navy-500 flex flex-col items-start mt-2">
													<span className=" font-omnes-medium">
														Shipping Method
													</span>
													<span className="font-omnes-regular">
														<span className="font-omnes-regular">
															{orderBoxDetails
																?.shippingMethod
																?.name
																? orderBoxDetails
																		.shippingMethod
																		?.name
																: "—"}
														</span>
													</span>
												</div>
											</>
										}
									/>
								</>
							}
						/>
						<BaseContainer
							columnContainerClasses={billToBaseContainerClasses}
							columnShape="semi-rounded"
							borderColor="navy-200"
							backgroundColor="bg-navy-50"
							isDisabled={false}
							children={
								<>
									<OrderContentSection
										sectionContainerClasses={
											individualSectionContainerClasses
										}
										detailsContainerClasses="w-full pt-3"
										hostName={hostName}
										sectionTitle={
											<BaseText
												size={"text-sm"}
												color={"text-navy-500"}
												text={"Bill To"}
												fontFamily={"font-omnes-medium"}
												tag={"h2"}
											/>
										}
										children={
											<>
												<BaseContactInfo
													contactData={
														orderBoxDetails?.contact
													}
												/>
												<BaseAddress
													title={
														<BaseText
															text={"Location"}
															size={"text-sm"}
															color={
																"text-navy-500"
															}
															tag={"h2"}
															fontFamily={
																"font-omnes-medium"
															}
														/>
													}
													addressLines={[
														orderBoxDetails
															?.shipToAddress
															?.addressee ?? "",
														`Attn: ${orderBoxDetails?.shipToAddress?.attention}`,
														orderBoxDetails
															?.shipToAddress
															?.line1 ?? "",
														orderBoxDetails
															?.shipToAddress
															?.line2 ?? "",
														`${
															orderBoxDetails
																?.shipToAddress
																?.city ?? ""
														}, ${
															orderBoxDetails
																?.shipToAddress
																?.state?.code ??
															""
														} ${
															orderBoxDetails
																?.shipToAddress
																?.zip ?? ""
														}`,
														orderBoxDetails
															?.shipToAddress
															?.phoneNumber ?? "",
													]}
													additionalClasses="pt-3"
												/>
											</>
										}
									/>
								</>
							}
						/>
						{hostName !== "PRUDENTIAL" && (
							<BaseContainer
								columnContainerClasses={
									orderSummaryBaseContainerClasses
								}
								columnShape="semi-rounded"
								borderColor="none"
								backgroundColor=""
								isDisabled={false}
								children={
									<>
										<OrderContentSection
											data={orderSummary}
											hostName={hostName}
											dataLabel={"ORDERSUMMARY"}
											valueType={"detail"}
											detailsContainerClasses="w-full pt-3"
											sectionContainerClasses={`${individualSectionContainerClasses} mb-10`}
											fields={
												dummyOrderDetailsFields
													.DUMMYORDERDETAILSFIELDS[2]
													.ORDERSUMMARY
											}
											isDisabled={false}
											sectionTitle={
												<BaseText
													size={"text-sm"}
													color={"text-navy-500"}
													text={"Order Summary"}
													fontFamily={
														"font-omnes-medium"
													}
													tag={"h2"}
												/>
											}
										/>
										{orderSummary._totalLease !== "0.00" &&
											hostName === "COMPASS" && (
												<OrderContentSection
													data={orderSummary}
													hostName={hostName}
													dataLabel={
														"RECURRINGCHARGES"
													}
													valueType={"detail"}
													detailsContainerClasses="w-full"
													sectionContainerClasses={
														individualSectionContainerClasses
													}
													fields={
														dummyOrderDetailsFields
															.DUMMYORDERDETAILSFIELDS[3]
															.RECURRINGCHARGES
													}
													isDisabled={false}
													sectionTitle={
														<BaseText
															size={"text-sm"}
															color={
																"text-navy-500"
															}
															text={
																"Recurring Charges"
															}
															fontFamily={
																"font-omnes-medium"
															}
															tag={"h2"}
														/>
													}
												/>
											)}
									</>
								}
							/>
						)}
					</section>
					<section>
						<div className="overflow-auto font-omnes-regular">
							<div className={tableHeaderContainerClasses}>
								<h2 className="font-omnes-medium text-xl text-navy-500 leading-6">
									{"Items"}
								</h2>
							</div>
							<OrderItemsTable
								data={data}
								isLoading={isLoading}
								openModal={toggleModal}
							/>
						</div>
					</section>
					<section className={invoiceAndShipmentSectionClasses}>
						{invoiceData.length !== 0 &&
							hostName !== "PRUDENTIAL" && (
								<div
									className={`${
										salesOrderNotesNotAdmin
											? " w-5/12"
											: "w-1/2"
									}  flex flex-col items-start  max-xl:w-full mb-30 max-xl:mb-6`}
								>
									<BaseContainer
										columnContainerClasses={
											invoicesBaseContainerClasses
										}
										columnShape="semi-rounded"
										borderColor="navy-200"
										backgroundColor="bg-navy-100"
										isDisabled={false}
										children={
											<div className="flex flex-col items-start justify-between w-full h-full">
												<div
													className={`${
														salesOrderNotesNotAdmin
															? ""
															: ""
													} flex flex-col w-full`}
												>
													<div className="flex">
														<BaseText
															text={"Invoices"}
															size="text-sm"
															fontFamily="font-omnes-medium"
															tag="h3"
															color="text-navy-500"
														/>
													</div>
													<div className="w-full py-3">
														<OrderInvoiceList
															items={invoiceData}
															fields={
																dummyOrderDetailsFields
																	.DUMMYORDERDETAILSFIELDS[3]
																	?.INVOICES ||
																[]
															}
														></OrderInvoiceList>
													</div>
												</div>
												<div className="flex w-full items-end">
													<BaseText
														text={`${invoiceData.length} invoice(s)`}
														size="text-sm"
														fontFamily="font-omnes-regular"
														tag="h3"
														color="text-navy-300"
													/>
												</div>
											</div>
										}
									/>
								</div>
							)}

						{data?.shipments?.length !== 0 && (
							<div
								className={` ${
									salesOrderNotesNotAdmin
										? " w-2/12"
										: "w-1/2"
								} flex flex-col items-start  max-xl:w-full mb-30 max-xl:ml-0`}
							>
								<BaseContainer
									columnContainerClasses={
										shipmentsBaseContainerClasses
									}
									columnShape="semi-rounded"
									borderColor="navy-200"
									backgroundColor="bg-navy-100"
									isDisabled={false}
									children={
										<div
											className={
												"flex flex-col items-start w-full h-full"
											}
										>
											<div
												className={
													"flex flex-col items-start w-full h-full"
												}
											>
												<BaseText
													text={"Shipments"}
													size="text-sm"
													fontFamily="font-omnes-medium"
													tag="h3"
													color="text-navy-500"
												/>
												<div
													className={`${
														salesOrderNotesNotAdmin
															? " grid grid-cols-1"
															: "grid grid-cols-3"
													}  gap-2 w-full py-3 max-lg:grid-cols-1`}
												>
													{data?.shipments?.map(
														(
															shipment: Shipments
														) => (
															<div
																key={
																	shipment.uuid
																}
																className="flex"
															>
																<OrderShipment
																	shipmentData={
																		shipment
																	}
																	containerClasses="w-full"
																	shape="rounded-lg"
																	borderColor=""
																	backgroundColor="bg-white"
																	additionalClasses="col-span-1 h-[76px]"
																	fontFamily="font-omnes-regular"
																	textSize="text-sm"
																/>
															</div>
														)
													)}
												</div>
											</div>
											<div className="flex w-full h-1/4 items-end">
												<BaseText
													text={`${data?.shipments?.length} shipment(s)`}
													size="text-sm"
													fontFamily="font-omnes-regular"
													tag="h3"
													color="text-navy-300"
												/>
											</div>
										</div>
									}
								/>
							</div>
						)}

						<div className="gap-2 flex flex-col lg:w- w-5/12 ">
							{shouldRenderDetails && (
								<BaseContainer
									columnShape="semi-rounded"
									borderColor="navy-200"
									backgroundColor="bg-navy-100"
									isDisabled={false}
									columnContainerClasses=" text-sm flex flex-col max-xl:w-full py-3 px-4 max-xl:mb-6 min-h-28  text-left border-transparent"
								>
									<>
										<div className="font-omnes-medium mb-3">
											Additional Order Details
										</div>
										<div className="grid grid-cols-3 gap-4">
											<div>
												<div>Priority</div>
												<div>
													<span className="text-flagIndigo pr-2">
														{getFontAwesomeIcon(
															"flag",
															"solid"
														)}
													</span>
													{priority}
												</div>
											</div>
											<div>
												<div>Submitted by</div>
												<div>{submittedBy}</div>
											</div>
											<div>
												<div>Notifications</div>
												<div>
													{notifications.map(
														(
															notification: Record<
																string,
																any
															>,
															index: number
														) => (
															<div key={index}>
																{notification?.emailAddress ||
																	"—"}
															</div>
														)
													)}
												</div>
											</div>
										</div>
									</>
								</BaseContainer>
							)}

							{salesOrderNotesNotAdmin && (
								<div className="flex items-start max-xl:w-full mb-4 max-xl:ml-0 gap-2 flex-wrap">
									{salesOrderNotesNotAdmin.map(
										(
											{
												note,
												salesOrderNoteType,
											}: {
												note: string | null;
												salesOrderNoteType: {
													name: string | null;
												};
											},
											index: number
										) => (
											<BaseContainer
												key={index}
												columnContainerClasses={
													"text-sm flex flex-col w-[49%] max-xl:w-full py-3 px-4 max-xl:mb-6 min-h-32 max-h-32 overflow-auto text-left border-transparent"
												}
												columnShape="semi-rounded"
												borderColor="navy-200"
												backgroundColor="bg-navy-100"
												isDisabled={false}
												children={
													<>
														<div className="font-omnes-medium">
															{salesOrderNoteType?.name ??
																"—"}
														</div>

														<div>
															{note &&
															note !== "null"
																? note
																: "—"}
														</div>
													</>
												}
											/>
										)
									)}
								</div>
							)}
						</div>
						{isAdmin && activeUuid && (
							<div className="gap-2 flex flex-col w-1/2">
								<BaseContainer
									columnShape="semi-rounded"
									borderColor="navy-200"
									backgroundColor="bg-navy-100"
									isDisabled={false}
									columnContainerClasses=" text-sm flex flex-col max-xl:w-full py-3 px-4 max-xl:mb-6 min-h-28  text-left border-transparent"
								>
									<AddNotesForm
										notesData={adminNotes}
										openViewAllNotes={toggleViewAllNotes}
										showViewAllButton={true}
										title={
											<BaseText
												text={"Notes"}
												fontFamily="font-omnes-medium"
												tag="h1"
												size="text-sm"
												color="text-navy-500"
												additionalClasses=""
											/>
										}
										showBottomNoteCount={true}
										notesPlacementBottom={true}
										refetchData={() =>
											setRefetchData(
												(prevState) => !prevState
											)
										}
										notesListMaxHeight="max-h-[368px] overflow-scroll"
										salesOrderUuid={activeUuid}
									/>
								</BaseContainer>
							</div>
						)}

						{/* {TESTRENDER && (
							<div className="flex flex-col items-start w-1/5 max-xl:w-full mb-30 max-xl:ml-0">
								<BaseContainer
									columnContainerClasses={
										shipmentsBaseContainerClasses
									}
									columnShape="semi-rounded"
									borderColor="navy-200"
									backgroundColor="bg-navy-100"
																				isDisabled={false}

									children={
										<div
											className={
												individualSectionContainerClasses
											}
										>
											<div
												className={
													individualSectionContainerClasses
												}
											>
												<BaseText
													text={"Order Activity"}
													size="text-sm"
													fontFamily="font-omnes-medium"
													tag="h3"
													color="text-navy-500"
													additionalClasses="mb-4"
												/>
												<div className={""}>
													<OrderTransmissionActivity
														transmissionActivity={
															transmissionActivityItem
														}
													/>
												</div>
											</div>
										</div>
									}
								/>
							</div>
						)} */}
					</section>
					{fulfillmentModal}
					{isViewAllNotesOpen && activeUuid && (
						<SupplyPortal containerId="portal-root">
							<BaseModal
								containerClassNames="overflow-auto"
								header={<></>}
								isOpen={true}
								onClose={toggleViewAllNotes}
								children={
									<motion.div
										className="fixed inset-0 z-50 flex flex-col items-center justify-center"
										variants={modalVariants}
										initial="hidden"
										animate="visible"
										// onClick={handleBackgroundClick}
									>
										<BaseContainer
											columnContainerClasses="flex  w-[1020px] h-[800px] flex-col items-start bg-white p-5 rounded-xl border border-gray-200"
											columnShape="semi-rounded"
											borderColor="navy-200"
											isDisabled={false}
										>
											<div className="flex w-full items-center justify-between">
												<BaseText
													text={`Notes (${adminNotes.length})`}
													fontFamily="font-omnes-medium"
													tag="h1"
													size="text-lg"
													color="text-navy-500"
													additionalClasses=""
												/>
												<BaseButton
													bgColor="bg-white"
													additionalClasses=""
													iconAriaLabel="Close"
													icon={getFontAwesomeIcon(
														"Xmark",
														"regular"
													)}
													as="button"
													onClick={() =>
														toggleViewAllNotes()
													}
													iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
												/>
											</div>

											<AddNotesForm
												notesData={adminNotes}
												openViewAllNotes={
													toggleViewAllNotes
												}
												showBottomNoteCount={false}
												refetchData={() =>
													setRefetchData(
														(prevState) =>
															!prevState
													)
												}
												notesListMaxHeight="h-[600px] overflow-scroll"
												salesOrderUuid={activeUuid}
												addNoteButtonStyles={
													"bg-gray-100"
												}
											/>
										</BaseContainer>
									</motion.div>
								}
							/>
						</SupplyPortal>
					)}
					<>
						<AnimatePresence>
							{isEventLogOpen && (
								<motion.div
									initial={slideOutMenuVariants.initial}
									animate={slideOutMenuVariants.animate}
									exit={slideOutMenuVariants.exit}
									transition={slideOutMenuVariants.transition}
									className={slideOutMenuClassNames}
									style={{
										height: overlayHeight,
									}}
								>
									<>
										<div className={menuItemClassNames}>
											<EventLog
												title={eventLogTitle}
												isOpen={isEventLogOpen}
												parentData={data?.salesOrder}
												slug={"sales-orders"}
												hasActivityToggle={true}
											/>
										</div>
									</>
								</motion.div>
							)}
						</AnimatePresence>
						<AnimatePresence>
							{isEventLogOpen && (
								<motion.div
									initial={slideOutMenuOverlayVariant.initial}
									animate={slideOutMenuOverlayVariant.animate}
									exit={slideOutMenuOverlayVariant.exit}
									transition={
										slideOutMenuOverlayVariant.transition
									}
									onClick={toggleMenu}
									className={overlayClassNames}
									style={{
										height: overlayHeight,
									}}
								></motion.div>
							)}
						</AnimatePresence>
					</>
				</div>
			</div>
		</>
	);
};

export default OrderViewModalContent;
