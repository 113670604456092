import React, { useRef, useState, useMemo } from "react";
import { ColumnInstance, Column } from "react-table";
import InputAndCheck from "../InputAndCheck";
// import HeaderContent from "../HeaderContent";
import TableHeaderContent from "@agilant/toga-blox/dist/components/TableHeaderContent/TableHeaderContent";
import MagnifyingIcon from "../MagnifyingIcon";
import useClickOutsideOfElement from "../../../../hooks/useClickOutsideOfElement";
import SupplyPortal from "../../../misc/SupplyPortal";
import { PrimaryTableHeaderProps } from "../types";
import { SortArrows } from "..";

// This portal overlay creates a transparent overlay that covers the entire screen when the user is editing a header.
const PortalOverlay: React.FC = () => {
	return (
		<SupplyPortal containerId="portal-root">
			<div className="w-full h-full absolute top-0"></div>
		</SupplyPortal>
	);
};

const ItemTableHeader = <T extends object>({
	headerGroups,
	editingHeader,
	searchText,
	setSearchText,
	setSortColumn,
	setEditingHeader,
	setHeaderValue,
	setResetSearch,
	columnMappings: ColumnMapping,
	setSearchColumn,
	isNested,
	parentIndex,
	searchResultsPillColor,
	onHeaderSortClick,
}: PrimaryTableHeaderProps<T>): JSX.Element => {
	const [_, setActiveColumnFromSearch] = useState<string>("");
	const [activeColumn, setActiveColumn] = useState<number | null>(null);
	const [__, setActiveSortColumn] = useState<string | null>(null);
	const [___, setSortDirection] = useState<string | null>(null);

	// Align headerNames and mappingFields based on their index positions
	const { headerNames, mappingFields } = useMemo(() => {
		// Generate headerNames from headerGroups
		const headerNames: string[] = [];
		headerGroups.forEach((headerGroup) => {
			headerGroup.headers.forEach((column) => {
				let headerName = "";

				if (typeof column.Header === "string") {
					headerName = column.Header;
				} else if (
					typeof column.Header === "function" &&
					column.Header.displayName
				) {
					headerName = column.Header.displayName;
				}

				headerNames.push(headerName);
			});
		});

		// Generate mappingFields from ColumnMapping, sorted by index
		let mappingFields: string[] = [];
		if (ColumnMapping && Array.isArray(ColumnMapping)) {
			// Sort ColumnMapping by 'index' to align with headerNames
			const sortedColumnMapping = [...ColumnMapping].sort(
				(a, b) => (a.index ?? 0) - (b.index ?? 0)
			);

			mappingFields = sortedColumnMapping.map((cm) => {
				const field = cm.slug || "";
				return field.replace(/^_+/, "");
			});
		}

		return { headerNames, mappingFields };
	}, [headerGroups, ColumnMapping]);

	const containerRef = useRef<HTMLDivElement>(null);
	const magnifyingIconRef = useRef<HTMLDivElement>(null);

	useClickOutsideOfElement(
		containerRef,
		() => setEditingHeader(null),
		magnifyingIconRef
	);

	const handleSortChange = (columnId: string, direction: string) => {
		onHeaderSortClick(columnId, direction);
	};

	const inputRef = useRef<HTMLInputElement>(null);

	const divClassNames =
		"items-center text-sm px-1 min-h-8 flex break-words whitespace-normal px-5 overflow- rounded-lg";
	const theadStyles = "bg-supply-blue-50 w-full sticky top-0 z-10";
	const textStyles = "text-navy-500 font-light text-sm text-nowrap";

	return (
		<thead className={theadStyles}>
			{headerGroups.map((headerGroup, headerGroupIndex) => (
				<tr
					className=""
					{...headerGroup.getHeaderGroupProps()}
					key={`header-group-${headerGroupIndex}`}
				>
					{headerGroup.headers.map((column, columnIndex) => {
						const inputKey = `header-${headerGroupIndex}-column-${columnIndex}`;
						const isFirstColumn = columnIndex === 0;
						const isLastColumn =
							columnIndex === headerGroup.headers.length - 1;
						const roundedClasses = isFirstColumn
							? "rounded-tl-lg "
							: isLastColumn
							? "rounded-tr-lg "
							: "";

						// Get the headerName and mappingField for this index
						const headerName = headerNames[columnIndex];
						const mappingField = mappingFields[columnIndex];

						// Create updatedColumn for internal use, keep headerName for rendering
						const updatedColumn = {
							...column,
							Header: column.Header,
							accessor:
								mappingField ||
								((column as Column<T>).accessor ?? ""),
						};

						const shouldRenderMagnifyingIcon = !isLastColumn;

						return (
							<th
								className={`${textStyles} ${roundedClasses}`}
								{...column.getHeaderProps()}
								key={`header-${headerGroupIndex}-column-${columnIndex}`}
							>
								<div className={`${divClassNames} relative`}>
									{editingHeader === columnIndex ? (
										<>
											<TableHeaderContent
												column={updatedColumn}
											/>
											<div
												className="absolute w-full top-[-5px] bottom-4"
												ref={containerRef}
											>
												<div
													className={`absolute top-10 z-20 ${
														isLastColumn ? "" : ""
													}`}
												>
													<InputAndCheck
														setEditingHeader={
															setEditingHeader
														}
														column={updatedColumn}
														parentIndex={
															parentIndex
														}
														closeOutSearch={() =>
															setEditingHeader(
																null
															)
														}
														setResetSearch={
															setResetSearch
														}
														pillColor={
															searchResultsPillColor
														}
													/>
													{(() => {
														inputRef.current?.focus();
														return null;
													})()}
												</div>
											</div>
											<PortalOverlay />
										</>
									) : (
										<TableHeaderContent
											column={updatedColumn}
										/>
									)}

									<div className="ml-1">
										<SortArrows
											// Use mappingField for sorting (e.g., in URLs)
											slug={mappingField || ""}
											parentIndex={parentIndex}
											isNested={isNested}
											column={{
												...updatedColumn,
												accessor: String(
													updatedColumn.accessor
												), // Narrow to string
											}}
											setSortColumn={setSortColumn}
											onSortChange={handleSortChange}
										/>
									</div>

									{shouldRenderMagnifyingIcon && (
										<MagnifyingIcon
											ref={magnifyingIconRef}
											setActiveColumnFromSearch={
												setActiveColumnFromSearch
											}
											setSearchColumn={setSearchColumn}
											editingHeader={editingHeader}
											columnIndex={columnIndex}
											setEditingHeader={setEditingHeader}
											setHeaderValue={setHeaderValue}
											column={
												updatedColumn as ColumnInstance<T>
											}
											setSearchText={setSearchText}
											searchText={searchText}
											isActive={
												activeColumn === columnIndex
											}
											setActiveColumn={setActiveColumn}
											isSearchOpen={
												editingHeader !== null
											}
										/>
									)}
								</div>
							</th>
						);
					})}
				</tr>
			))}
		</thead>
	);
};

export default ItemTableHeader;
