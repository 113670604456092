// GENERAL
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";

// COMPONENTS
import BaseButton from "../../../../../components/ui/BaseButton";
import BaseText from "../../../../../components/ui/BaseText";
import ToggleButton from "../../../../../components/ui/ToggleButton";
import BasePill from "../../../../../components/ui/BasePill";
import EventLog from "../../../../../components/ui/EventLog/EventLog";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import VerticalImageCarousel from "../../../../../components/ui/VerticalImageCarousel";
import ModalContentSection from "../../../../../components/layout/Modal/ModalContentSection";
import PrimaryTable from "../../../../../components/ui/Tables/PrimaryTable/PrimaryTable";
import Image from "../../../../../components/layout/ImageComponent";
import usePageListDetails from "../../../../PageHooks/usePageListDetails";
import SupplyFormGuardrail from "../../../../../components/misc/SupplyFormGuardrail";

// UTILS, HOOKS, API, ASSETS, TYPES
import dummyItemFields from "./DUMMYITEMFIELDS.json";
import { useToaster } from "../../../../../hooks/useToasterContext";
import placeholderImage from "../../../../../assets/placeholder-no-image-available.png";
import { DataTableProvider } from "../../../../../hooks/dataTableHooks/useDataTableContext";
import { updateItemDetails } from "../../../api/itemsApi";
import { ItemsContextProvider } from "../../../../../hooks/dataTableHooks/useItemsContext";
import {
	Guardrail,
	ItemsModalContentTemplateProps,
	PersonaObject,
	PersonaObjectForBackend,
	FormData,
} from "../../../types";
import getFontAwesomeIcon from "../../../../../utils/getFontAwesomeIcon";
import {
	slideOutMenuOverlayVariant,
	slideOutMenuVariants,
	tableVariants,
} from "../../../../../motionVariants";
type Image = {
	highImage: {
		sourceUrl: string;
	};
	lowImage: {
		sourceUrl: string;
	};
	mediumImage: {
		sourceUrl: string;
	};
};

const ItemsModalContentTemplate: React.FC<ItemsModalContentTemplateProps> = ({
	modalRef,
	hasBorder = false,
	borderColor = "primary",
	parentData,
	onClose,
	parentSelectsData,
	setIsRefresh,
	setIsLoading,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [toggleStatus, setToggleStatus] = useState<boolean>(false);
	const [toggleDisabled, setToggleDisabled] = useState<boolean>(true);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [vendorItems, setVendorItems] = useState<any[]>([]);
	const [item, setItem] = useState<any>("");
	const [originalItemData, setOriginalItemData] = useState<any>("");
	const [parentItemSelectsData, setParentItemSelectsData] = useState<any>("");
	const { pageMeta } = usePageListDetails("items-view");
	const [guardrail, setGuardrail] = useState<Guardrail | null>(null);
	const { toasters, addToaster } = useToaster();

	useEffect(() => {
		if (parentData && parentSelectsData) {
			setItem(parentData);
			setOriginalItemData(parentData);
			setParentItemSelectsData(parentSelectsData);
			setVendorItems(parentData.vendorItems);

			if (parentData.isActive) {
				setToggleStatus(parentData.isActive);
			}

			if (parentData.personaItems) {
				let personaItems: { uuid: string; name: string }[] = [];

				personaItems = parentData.personaItems.map(
					({ persona: { uuid, number, name } }: PersonaObject) => ({
						uuid,
						name: `${number} - ${name}`,
					})
				);

				const uniquePersonaItems = Array.from(
					new Map(
						personaItems.map((item) => [item.uuid, item])
					).values()
				);

				const updatedItem = {
					...parentData,
					restrictToPersona: uniquePersonaItems,
				};
				setItem(updatedItem);
			}
		}
	}, [parentData]);

	const formMethods = useForm<FormData>({
		mode: "all",
		defaultValues: item || {},
	});

	const { reset, handleSubmit, setError } = formMethods;

	const initialItemRef = useRef(item);

	useEffect(() => {
		if (item && item !== initialItemRef.current) {
			reset({
				...formMethods.getValues(),
				...item,
			});
			initialItemRef.current = item;
		}
	}, [item, reset, formMethods]);

	const onFormSubmit = async (data: FormData) => {
		let personaItems: { uuid: string }[] = [];
		let newData;
		if (data.restrictToPersona && data.restrictToPersona.length > 0) {
			personaItems = data.restrictToPersona.map(
				({ uuid }: PersonaObjectForBackend) => ({
					uuid,
				})
			);
		}
		if (
			data.defaultPriceType.name === "Lease" &&
			(data._leasePrice === "" || data._leasePrice === null)
		) {
			setError("_leasePrice", {
				type: "required",
				message: "Required field.",
			});
		} else if (
			data.defaultPriceType.name === "Each" &&
			(data._unitPrice === "" || data._unitPrice === null)
		) {
			setError("_unitPrice", {
				type: "required",
				message: "Required field.",
			});
		} else {
			const { restrictToPersona, ...restData } = data;
			newData = {
				...restData,
				isActive: toggleStatus,
				personaItems,
			};

			const uuid = item.uuid;

			try {
				const response = await updateItemDetails(
					uuid,
					originalItemData,
					newData
				);
				if (response.status === 200) {
					setIsEditMode(!isEditMode);
					setItem(newData);
					setOriginalItemData(newData);
					addToaster({
						isOpen: true,
						message: "Item details updated successfully",
						status: "success",
					});
					setIsRefresh((prev) => !prev);
				} else {
					addToaster({
						isOpen: true,
						message: "Failed to update item details",
						status: "error",
					});
				}
			} catch (error) {
				addToaster({
					isOpen: true,
					message: "An error occurred while updating item details",
					status: "error",
				});
			}
		}
	};

	const handleActiveStateGuardrail = () => {
		if (toggleStatus !== originalItemData.isActive || guardrail) {
			setGuardrail({
				isOpen: true,
				messageOne: `Save changes, including Active Status updates?`,
				messageTwo: `This will save your changes and set the Active Status to ${!originalItemData.isActive}.`,
			});
		} else {
			handleSubmit(onFormSubmit)();
			setGuardrail(null);
		}
	};

	const handleGuardrailSave = () => {
		handleSubmit(onFormSubmit)();
		setGuardrail(null);
	};

	const handleModalClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
	};

	const handleClose = () => {
		const currentFormValues = formMethods.getValues();
		const isFormDirty =
			JSON.stringify(currentFormValues) !==
				JSON.stringify(initialItemRef.current) ||
			toggleStatus !== originalItemData.isActive;
		if (isFormDirty && isEditMode) {
			setGuardrail({
				isOpen: true,
				messageOne: `Do you want to save the changes made to ${item.title}?`,
				messageTwo: "Your changes will be lost if you don’t save them.",
			});
		} else {
			setIsMenuOpen(false);
			reset();
			onClose();
			setGuardrail(null);
			document.body.classList.remove("overflow-hidden");
		}
	};

	const handleToggleCancel = () => {
		const currentFormValues = formMethods.getValues();
		const isFormDirty =
			JSON.stringify(currentFormValues) !==
			JSON.stringify(initialItemRef.current);

		if (isFormDirty && isEditMode) {
			setGuardrail({
				isOpen: true,
				messageOne: `Do you want to save the changes made to ${item.title}?`,
				messageTwo: "Your changes will be lost if you don’t save them.",
			});
		} else {
			setToggleStatus(item.isActive);
			reset();
			setToggleDisabled(!toggleDisabled);
			setIsEditMode(false);
			setGuardrail(null);
		}
	};

	const handleGuardrailDoNotSave = () => {
		setToggleStatus(item.isActive);
		reset();
		setToggleDisabled(!toggleDisabled);
		setIsEditMode(false);
		setGuardrail(null);
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	// FORM CLASSES:
	const formClassNames =
		"flex justify-around w-full max-md:flex-col overflow-visible gap-10";
	const modalBorderClassNames = `overflow-scroll rounded-lg  bg-white min-w-90%, border-2  max-h-[95vh] px-8 ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}  ${hasBorder ? `border-${borderColor}` : "border-transparent"}`;
	// HEADER CLASSES:
	const headerContainerClassNames =
		"flex flex-col items-between justify-between sticky top-[-5px] bg-white max-lg:flex-col min-h-32 pt-2";
	const headerTitleAndButtonsContainerClassNames =
		"flex items-start max-lg:flex-col justify-between w-full pb-2";
	const headerTitlesContainerClassNames =
		"flex w-1/2 max-lg:w-full max-lg:ml-1 justify-between";
	const headerButtonContainerClassNames =
		"flex w-1/2 justify-end  max-lg:pt-3 max-lg:justify-start";
	const mobileExitButtonClassNames = "hidden max-lg:flex justify-end py-2";
	// SLIDEOUT MENU CLASSES:
	const slideOutMenuClassNames = `slide-out-menu bg-white w-1/2 min-w-[300px] max-w-[500px] absolute top-0 left-0 z-40`;
	const overlayClassNames = `bg-black absolute z-1 w-full left-0 top-0 cursor-pointer `;
	const overlayHeight = modalRef.current
		? modalRef.current.scrollHeight + "px"
		: "100%";
	const menuItemClassNames = "sticky min-h-full max-h-full mb-20";
	const itemCreatedText = "Item Created";
	const eventLogTitle = "Event Log";

	//Building Additional Data object
	const additionalData = {
		slug: "Items",
		uuid: item.uuid,
	};
	return (
		item && (
			<div
				ref={modalRef}
				className={modalBorderClassNames}
				onClick={handleModalClick}
			>
				<header aria-label="vendor items modal header.">
					{guardrail && (
						<>
							<div
								className={`bg-black opacity-10 absolute z-40 w-full left-0 top-0 `}
								style={{ height: "100vh", width: "100vw" }}
							>
								<SupplyFormGuardrail
									messages={guardrail}
									onDoNotSave={() =>
										handleGuardrailDoNotSave()
									}
									onCancel={() => setGuardrail(null)}
									onSave={() => handleGuardrailSave()}
								/>
							</div>
						</>
					)}

					<header
						className={headerContainerClassNames}
						aria-label="Items modal header."
					>
						<div className="flex flex-col px-1 py-4">
							<div
								className={
									headerTitleAndButtonsContainerClassNames
								}
							>
								<div
									className={headerTitlesContainerClassNames}
								>
									<div className="flex items-center">
										{
											<BasePill
												text={
													<BaseText
														size={"text-sm"}
														color={"text-slate-950"}
														text={"Item"}
														fontFamily={
															"font-omnes-medium"
														}
														tag={"h1"}
													/>
												}
												firstIcon={getFontAwesomeIcon(
													"box",
													"solid"
												)}
												hoverBackgroundColor="none"
												additionalClasses="px-2.5 py-1 h-26px"
												shape="rounded-full"
												color="bg-supply-blue-50"
												borderColor="transparent"
												type={"span"}
												hasMobileStyle={false}
											/>
										}
										<div className="px-2 text-left">
											{
												<BaseText
													size={"text-16px"}
													color={"text-navy-350"}
													text={`${
														item?.manufacturer
															?.name || "—"
													} - Part #: ${
														item?.partNumber
													}`}
													fontFamily={""}
													tag={"h2"}
												/>
											}
										</div>
									</div>
									<div className={mobileExitButtonClassNames}>
										{
											<BaseButton
												text=""
												additionalClasses={`p-2 mx-4px`}
												iconAriaLabel="Close"
												icon={getFontAwesomeIcon(
													"Xmark",
													"regular"
												)}
												as="button"
												onClick={handleClose}
												iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
											/>
										}
									</div>
								</div>
								<div className="hidden text-left max-lg:flex ml-1">
									<BaseText
										size={"text-22px"}
										color={"text-navy-500"}
										text={
											item?.title && item.title !== "—"
												? item.title
												: "Item Title Not Provided"
										}
										fontFamily={"font-omnes-medium"}
										tag={"h2"}
									/>
								</div>
								<div
									className={headerButtonContainerClassNames}
								>
									{
										<ToggleButton
											initialStatus={toggleStatus}
											onClick={() => {
												setToggleStatus(!toggleStatus);
											}}
											textPosition={"right"}
											textSize={"md"}
											activeColorBackground="bg-mint-600"
											inactiveColorBackground="bg-crimson-500"
											activeTextColor="text-mint-700"
											inactiveTextColor="text-crimson-700"
											hasDisabledStatus={!isEditMode}
											additionalClasses={`w-1/4 py-2`}
											hasDivider={true}
											fontFamily="omnes-medium"
											activeLabel={"Active"}
											inactiveLabel={"Inactive"}
											id={"isActive"}
											pillHeight="h-7"
											smallToggle={false}
											borderStyle={false}
										/>
									}
									{isEditMode ? (
										<div className="flex">
											{
												<BaseButton
													text="Cancel"
													bgColor="bg-white"
													hoverBackground="hover:bg-primaryHoverLight"
													fontColor="text-primary"
													borderColor="border-1 border-primary"
													additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1`}
													shape="rounded-full"
													as="button"
													onClick={() =>
														handleToggleCancel()
													}
													fontFamily="font-omnes-medium"
													buttonSize="w-67px h-26px"
													fontSize="text-sm"
												/>
											}
											{
												<BaseButton
													type="button"
													text="Save"
													bgColor="bg-primary"
													fontColor="text-white"
													hoverBackground="hover:bg-primaryHover"
													additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1`}
													shape="rounded-full"
													as="button"
													fontFamily="font-omnes-medium"
													onClick={
														handleActiveStateGuardrail
													}
													buttonSize="w-67px h-26px"
													fontSize="text-sm"
												/>
											}
										</div>
									) : (
										<div className="flex">
											{
												<BaseButton
													text="Event Log"
													bgColor="bg-white"
													hoverBackground="hover:bg-primaryHoverLight"
													fontColor="text-primary"
													borderColor="border-1 border-primary"
													additionalClasses={`text-nowrap py-1 px-3 mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
													shape="rounded-full"
													icon={getFontAwesomeIcon(
														"clock",
														"regular"
													)}
													iconBreakpoint="hidden max-sm:flex max-sm:pr-0"
													as="button"
													textBreakpoint="max-sm:hidden"
													onClick={toggleMenu}
													fontFamily="font-omnes-medium"
													buttonSize="w-85px h-26px"
													iconClasses="text-primary"
												/>
											}
											{
												<BaseButton
													text="Edit"
													bgColor="bg-primary"
													fontColor="text-white"
													hoverBackground="hover:bg-primaryHover"
													buttonSize="w-85px h-26px"
													additionalClasses={`text-nowrap mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
													shape="rounded-full"
													icon={getFontAwesomeIcon(
														"edit",
														"regular"
													)}
													iconClasses="max-sm:pr-0 pr-2 text-white"
													as="button"
													textBreakpoint="max-sm:hidden"
													onClick={() => {
														setIsEditMode(true);
														setToggleDisabled(
															false
														);
													}}
													fontFamily="font-omnes-medium"
												/>
											}
										</div>
									)}
									<div className="flex max-lg:hidden">
										{
											<BaseButton
												text=""
												additionalClasses={`p-2 mx-4px`}
												iconAriaLabel="Close"
												icon={getFontAwesomeIcon(
													"Xmark",
													"regular"
												)}
												as="button"
												onClick={handleClose}
												iconClasses="flex items-center text-lg pr-0 size-3 text-navy-300 hover:text-navy-500"
											/>
										}
									</div>
								</div>
							</div>
							<div className="flex text-left max-lg:hidden">
								<BaseText
									size={"text-22px"}
									color={"text-navy-500"}
									text={
										item?.title && item.title !== "—"
											? item.title
											: "Item Title Not Provided"
									}
									fontFamily={"font-omnes-medium"}
									tag={"h2"}
								/>
							</div>
						</div>
					</header>
				</header>

				<>
					<div key="randomKey1" className="overflow-hidden p-1">
						{!isEditMode ? (
							<AnimatePresence>
								<motion.div key="motionDiv1" {...tableVariants}>
									<div className="flex justify-around w-full max-md:flex-col gap-10">
										<BaseContainer
											columnContainerClasses="flex flex-col w-2/5 max-md:w-full mb-30"
											columnShape="none"
											borderColor="transparent"
											isDisabled={false}
											children={
												<>
													{item.itemImages.length !=
													0 ? (
														<div className="flex items-start justify-center flex-col mb-6">
															<VerticalImageCarousel
																imageUrls={item.itemImages.map(
																	(
																		image: Image
																	) =>
																		image?.highImage?.sourceUrl
																)}
																containerMaxHeight="max-h-96"
																containerMinHeight="min-h-96"
																enabled={true}
															/>
														</div>
													) : (
														<div className="flex items-center justify-center flex-col mb-6">
															<Image
																imageUrl={
																	placeholderImage
																}
																altText="No Image Available"
																className="items-center size-54"
															/>
														</div>
													)}

													<ModalContentSection
														sectionTitle={
															<BaseText
																size={"text-md"}
																color={
																	"text-slate-950"
																}
																text={
																	"Overview"
																}
																fontFamily={
																	"font-omnes-medium"
																}
																tag={"h2"}
																additionalClasses="pb-1"
															/>
														}
														data={item}
														dataLabel="OVERVIEW"
														fields={
															dummyItemFields
																.DUMMYITEMFIELDS[0]
																.OVERVIEW
														}
														itemContainerClasses="w-full py-1"
														sectionContainerClasses="flex flex-col items-start"
														detailsContainerClasses="flex flex-col items-start w-full"
														mobileBreakpoint="max-md:flex-col"
														valueType={"detail"}
														isDisabled={false}
													/>
												</>
											}
										/>
										<BaseContainer
											columnContainerClasses="flex flex-col w-3/5 max-md:w-full h-full py-2.5 px-1.5"
											backgroundColor="bg-navy-100"
											borderColor="transparent"
											columnShape="rounded-full"
											isDisabled={false}
											children={
												<>
													<ModalContentSection
														sectionTitle={
															<BaseText
																size={"text-md"}
																color={
																	"text-slate-950"
																}
																text={"Pricing"}
																fontFamily={
																	"font-omnes-medium"
																}
																tag={"h2"}
																additionalClasses="pb-1"
															/>
														}
														data={item}
														dataLabel="PRICING"
														fields={
															dummyItemFields
																.DUMMYITEMFIELDS[1]
																.PRICING
														}
														itemContainerClasses="w-full py-1"
														sectionContainerClasses="flex flex-col items-start py-2 px-4"
														detailsContainerClasses="grid grid-cols-3 items-start w-full"
														mobileBreakpoint="max-md:flex-col"
														valueType={"detail"}
														isDisabled={false}
													/>
													<ModalContentSection
														sectionTitle={
															<BaseText
																size={"text-md"}
																color={
																	"text-slate-950"
																}
																text={
																	"Attributes"
																}
																fontFamily={
																	"font-omnes-medium"
																}
																tag={"h2"}
																additionalClasses="pb-1"
															/>
														}
														data={item}
														dataLabel="ATTRIBUTES"
														fields={
															dummyItemFields
																.DUMMYITEMFIELDS[2]
																.ATTRIBUTES
														}
														itemContainerClasses="w-full py-1"
														sectionContainerClasses="flex flex-col items-start py-2 px-4"
														detailsContainerClasses="grid grid-cols-2 items-start w-full"
														mobileBreakpoint="max-md:flex-col"
														valueType={"detail"}
														isDisabled={false}
													/>
													<ModalContentSection
														sectionTitle={
															<BaseText
																size={"text-md"}
																color={
																	"text-slate-950"
																}
																text={"Content"}
																fontFamily={
																	"font-omnes-medium"
																}
																tag={"h2"}
																additionalClasses="pb-1"
															/>
														}
														data={item}
														dataLabel="CONTENT"
														fields={
															dummyItemFields
																.DUMMYITEMFIELDS[3]
																.CONTENT
														}
														itemContainerClasses="w-full py-1"
														sectionContainerClasses="flex flex-col items-start py-2 px-4"
														detailsContainerClasses="flex flex-col items-start w-full"
														mobileBreakpoint="max-md:flex-col"
														valueType={"detail"}
														isDisabled={false}
													/>
													<ModalContentSection
														sectionTitle={
															<BaseText
																size={"text-md"}
																color={
																	"text-slate-950"
																}
																text={
																	"More Info"
																}
																fontFamily={
																	"font-omnes-medium"
																}
																tag={"h2"}
																additionalClasses="pb-1"
															/>
														}
														data={item}
														dataLabel="MOREINFO"
														fields={
															dummyItemFields
																.DUMMYITEMFIELDS[4]
																.MOREINFO
														}
														itemContainerClasses="w-full py-1"
														sectionContainerClasses="flex flex-col items-start py-2 px-4"
														detailsContainerClasses="flex flex-col items-start w-full"
														mobileBreakpoint="max-md:flex-col"
														valueType={"detail"}
														isDisabled={false}
													/>
												</>
											}
										/>
									</div>
								</motion.div>
							</AnimatePresence>
						) : (
							<AnimatePresence>
								<motion.div key="motionDiv2" {...tableVariants}>
									{/* FormProvider is providing context, allows BaseInput modal to access form methods */}
									<FormProvider {...formMethods}>
										<form
											className={formClassNames}
											onSubmit={handleSubmit(
												onFormSubmit
											)}
										>
											<BaseContainer
												columnContainerClasses="flex flex-col w-2/5 max-md:w-full mb-30"
												columnShape="none"
												borderColor="transparent"
												isDisabled={false}
												children={
													<>
														{item.itemImages
															.length != 0 ? (
															<div className="flex items-start justify-center flex-col mb-6">
																<VerticalImageCarousel
																	imageUrls={item.itemImages.map(
																		(
																			image: Image
																		) =>
																			image?.highImage?.sourceUrl
																	)}
																	containerMaxHeight="max-h-96"
																	containerMinHeight="min-h-96"
																	enabled={
																		false
																	}
																/>
															</div>
														) : (
															<div className="flex items-center justify-center flex-col mb-6">
																<Image
																	imageUrl={
																		placeholderImage
																	}
																	altText="No Image Available"
																	className="items-center size-54"
																/>
															</div>
														)}
														<ModalContentSection
															sectionTitle={
																<BaseText
																	size={
																		"text-md"
																	}
																	color={
																		"text-slate-950"
																	}
																	text={
																		"Overview"
																	}
																	fontFamily={
																		"font-omnes-medium"
																	}
																	tag={"h2"}
																	additionalClasses="pb-1"
																/>
															}
															valueType="input"
															data={item}
															dataLabel="OVERVIEW"
															fields={
																dummyItemFields
																	.DUMMYITEMFIELDS[0]
																	.OVERVIEW
															}
															selectOptions={
																parentItemSelectsData
															}
															itemContainerClasses="flex w-full"
															sectionContainerClasses="flex flex-col items-start w-full py-2"
															detailsContainerClasses="grid grid-cols-2 items-start w-full"
															mobileBreakpoint="max-md:flex-col"
														/>
													</>
												}
											/>
											<BaseContainer
												columnContainerClasses="flex flex-col w-3/5 max-md:w-full h-full py-2.5 px-1.5"
												backgroundColor="bg-navy-100"
												borderColor="transparent"
												columnShape="rounded-full"
												isDisabled={false}
												children={
													<>
														<ModalContentSection
															sectionTitle={
																<BaseText
																	size={
																		"text-md"
																	}
																	color={
																		"text-slate-950"
																	}
																	text={
																		"Pricing"
																	}
																	fontFamily={
																		"font-omnes-medium"
																	}
																	tag={"h2"}
																	additionalClasses="pt-0 pb-1"
																/>
															}
															valueType="input"
															data={item}
															dataLabel="PRICING"
															fields={
																dummyItemFields
																	.DUMMYITEMFIELDS[1]
																	.PRICING
															}
															selectOptions={
																parentItemSelectsData
															}
															itemContainerClasses="flex w-full"
															sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
															detailsContainerClasses="flex flex-col items-start w-full"
															mobileBreakpoint="max-md:flex-col"
														/>
														<ModalContentSection
															sectionTitle={
																<BaseText
																	size={
																		"text-md"
																	}
																	color={
																		"text-slate-950"
																	}
																	text={
																		"Attributes"
																	}
																	fontFamily={
																		"font-omnes-medium"
																	}
																	tag={"h2"}
																	additionalClasses="pb-1"
																/>
															}
															valueType="input"
															data={parentData}
															dataLabel="ATTRIBUTES"
															fields={
																dummyItemFields
																	.DUMMYITEMFIELDS[2]
																	.ATTRIBUTES
															}
															selectOptions={
																parentItemSelectsData
															}
															itemContainerClasses="flex w-full"
															sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
															detailsContainerClasses="grid grid-cols-2 items-start w-full"
															mobileBreakpoint="max-md:flex-col"
														/>
														<ModalContentSection
															sectionTitle={
																<BaseText
																	size={
																		"text-md"
																	}
																	color={
																		"text-slate-950"
																	}
																	text={
																		"Content"
																	}
																	fontFamily={
																		"font-omnes-medium"
																	}
																	tag={"h2"}
																	additionalClasses="pb-1"
																/>
															}
															valueType="input"
															data={item}
															dataLabel="CONTENT"
															fields={
																dummyItemFields
																	.DUMMYITEMFIELDS[3]
																	.CONTENT
															}
															selectOptions={
																parentItemSelectsData
															}
															itemContainerClasses="flex w-full"
															sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
															detailsContainerClasses="flex flex-col items-start w-full"
															mobileBreakpoint="max-md:flex-col"
														/>
														<ModalContentSection
															sectionTitle={
																<BaseText
																	size={
																		"text-md"
																	}
																	color={
																		"text-slate-950"
																	}
																	text={
																		"More Info"
																	}
																	fontFamily={
																		"font-omnes-medium"
																	}
																	tag={"h2"}
																	additionalClasses="pb-1"
																/>
															}
															valueType="input"
															data={item}
															dataLabel="MOREINFO"
															fields={
																dummyItemFields
																	.DUMMYITEMFIELDS[4]
																	.MOREINFO
															}
															selectOptions={
																parentItemSelectsData
															}
															itemContainerClasses="flex w-full"
															sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
															detailsContainerClasses="flex flex-col items-start w-full"
															mobileBreakpoint="max-md:flex-col"
														/>
													</>
												}
											/>
										</form>
									</FormProvider>
								</motion.div>
							</AnimatePresence>
						)}
						<div
							className={`mt-16 ${
								isEditMode
									? "cursor-not-allowed opacity-35 pointer-events-none"
									: ""
							}`}
						>
							<BaseText
								size={"text-22px"}
								color={"text-navy-500"}
								text={"Vendor Items"}
								fontFamily={"font-omnes-medium"}
								tag={"h2"}
								additionalClasses="text-left pb-4"
							/>
							<DataTableProvider
								pageMeta={pageMeta}
								pageTitle={"Items"}
							>
								<ItemsContextProvider
									hasModalTable={true}
									data={vendorItems}
								>
									<PrimaryTable
										setPageTitle={() => {}}
										slug={"vendor-items"}
										hasModalTable={true}
										pageTitle={"Vendor Items"}
										additionalData={additionalData}
									/>
								</ItemsContextProvider>
							</DataTableProvider>
							<div className="mb-40"></div>
						</div>
					</div>
				</>

				<>
					<AnimatePresence>
						{isMenuOpen && (
							<motion.div
								initial={slideOutMenuVariants.initial}
								animate={slideOutMenuVariants.animate}
								exit={slideOutMenuVariants.exit}
								transition={slideOutMenuVariants.transition}
								className={slideOutMenuClassNames}
								style={{
									height: overlayHeight,
								}}
							>
								<div className={menuItemClassNames}>
									<EventLog
										title={eventLogTitle}
										isOpen={isMenuOpen}
										parentData={parentData}
										slug={"items"}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{isMenuOpen && (
							<motion.div
								initial={slideOutMenuOverlayVariant.initial}
								animate={slideOutMenuOverlayVariant.animate}
								exit={slideOutMenuOverlayVariant.exit}
								transition={
									slideOutMenuOverlayVariant.transition
								}
								onClick={toggleMenu}
								className={overlayClassNames}
								style={{
									height: overlayHeight,
								}}
							></motion.div>
						)}
					</AnimatePresence>
				</>
			</div>
		)
	);
};
export default ItemsModalContentTemplate;
