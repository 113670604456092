import React, { useState } from "react";
import Toaster from "@agilant/toga-blox/dist/components/Toaster/Toaster";

type SupplyToasterProps = {
	status?: string;
	message: string;
	classNames?: string;
	hasClearButton?: boolean;
};

const getStatusStyles = (status: string) => {
	let colorClasses = "";
	let closeIconColor = "";
	let iconColor = "";

	switch (status) {
		case "success":
			colorClasses =
				"bg-mint-50 border-mint-600 text-mint-800 border-1 my-1  ";
			closeIconColor = "text-mint-600  absolute right-7 top-[22px] ";
			iconColor = "text-mint-600";
			break;
		case "error":
			colorClasses =
				"bg-crimson-300 border-crimson-500 text-crimson-700 border-1 my-1";
			closeIconColor =
				"text-crimson-500 absolute right-7 top-6 top-[22px]";
			iconColor = "text-crimson-600";
			break;
		default:
			colorClasses =
				"bg-gray-200 border-gray-600 text-gray-600 border-1 my-1";
			closeIconColor = "text-gray-600 absolute right-7 top-[22px]";
			iconColor = "text-gray-600";
			break;
	}

	return {
		colorClasses,
		closeIconColor,
		iconColor,
	};
};

const SupplyToaster: React.FC<SupplyToasterProps> = ({
	status = "default",
	message,
	classNames = "bg-navy-300 border-1 border-navy-600 text-navy-800",
	hasClearButton = true,
}) => {
	const [closeToaster, setCloseToaster] = useState<boolean>(false);

	const { colorClasses, closeIconColor, iconColor } = getStatusStyles(status);

	const supplyToasterClasses = `${classNames} p-2 rounded-2xl font-omnes-regular text-base  min-w-[400px]`;

	return (
		<>
			{!closeToaster && (
				<Toaster
					message={[message]}
					additionalClasses={supplyToasterClasses}
					hasClearButton={hasClearButton}
					clearMessage={setCloseToaster}
					closeIconColor={closeIconColor}
					iconColor={iconColor}
					statusProps={{
						colorClass: colorClasses,
						icon: {
							icon: "circleCheck",
							type: "solid",
						},
					}}
				/>
			)}
		</>
	);
};

export default SupplyToaster;
