import React, { useContext, useEffect, useState, FormEvent } from "react";
import {
	faRight,
	faAt,
	faLeft,
	faRightToBracket,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../components/ui/Input";
import Toaster from "../../components/misc/Toaster";
import UserContext from "../../hooks/useUserContext";
import { submitLoginPassword, validateEmailDomain } from "./api/LoginApi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDarkMode, useHostName, usePrivilegeCheck } from "../../hooks";
import { getFontAwesomeIcon, validateEmail } from "../../utils";
import BaseButton from "../../components/ui/BaseButton";
import PasswordInput from "../../components/ui/PasswordInput";
import { FormButton } from "../../components/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface LoginFormProps {
	onSubmit: (email: string) => void;
	onCheck?: (checked: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
	const { darkMode } = useDarkMode();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isError, setIsError] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [showEmailInput, setShowEmailInput] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const location = useLocation();
	const redirectTo =
		new URLSearchParams(location.search).get("redirect") || "/";
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [isChecked, setIsChecked] = useState(false);
	const { isAdmin, isSupervisor } = usePrivilegeCheck();
	const hostName = useHostName();

	localStorage.removeItem("approvalFilter");

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email") || "";
		if (email.length > 0) {
			setEmail(email);
			setShowEmailInput(false);
			setIsValidEmail(true);
		}
	}, []);

	const history = useNavigate();

	const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
		event?.preventDefault(); // Prevent the default form submission
		const isEmailValid = validateEmail(email);
		setIsValidEmail(isEmailValid);
		const isPasswordValid = password.length > 0;

		setIsError(!isEmailValid || !isPasswordValid);

		if (!isEmailValid || !isPasswordValid) {
			return;
		}

		//get the internalId from the url
		const urlParams = new URLSearchParams(window.location.search);
		const internalId = urlParams.get("internalId") || "";
		try {
			const isSuccess = await submitLoginPassword(
				email,
				password,
				setUser,
				history
			);
			if (isSuccess) {
				if (
					hostName.hostName === "Compass" &&
					!isAdmin &&
					!isSupervisor
				) {
					navigate("/unavailable");
					return;
				}
				onSubmit(email);
				if (internalId) {
					history("/shipment-items?internalId=" + internalId);
				} else {
					if (redirectTo) {
						history(redirectTo);
					} else if (history) {
						history("/");
					}
				}
			} else {
				setIsError(true);
			}
		} catch (error) {
			setApiError(true);
		}
	};

	const handleNext = async (event?: React.FormEvent<HTMLFormElement>) => {
		event?.preventDefault();
		const newIsValidEmail = validateEmail(email);
		setIsValidEmail(newIsValidEmail);
		if (newIsValidEmail) {
			// const validDomain = await validateEmailDomain(email);
			setEmail(email);
			setShowEmailInput(false);
			setIsValidEmail(true);
		}
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		handler: () => void
	) => {
		if (event.key === "Enter") {
			event.preventDefault(); // Prevent form submission
			handler();
		}
	};

	const handleGoBack = () => {
		setIsError(false);
		setPassword("");
		setShowEmailInput(true);
	};

	const handleRememberMeCheckbox = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsChecked(event.target.checked);
	};

	return (
		<form
			role="login-form"
			className="flex flex-col items-center justify-center"
			onSubmit={handleSubmit}
		>
			{showEmailInput ? (
				<>
					{!isValidEmail && (
						<Toaster
							clearMessage={() => setIsValidEmail(true)}
							message="Please provide a valid email address"
							additionalClasses="mb-4 border-red-500 w-2/3 text-center bottom-[100px] left-0 right-0"
							color="red-500"
						/>
					)}
					<div className="w-2/3 mx-auto">
						<Input
							firstIcon={getFontAwesomeIcon("envelope", "solid")}
							value={email}
							placeholder="Email *"
							type="email"
							id="email"
							onChange={(event) => setEmail(event.target.value)}
							label={""}
							required={false}
							name={"userEmail"}
							isValid={isValidEmail}
							onKeyDown={(event) =>
								handleKeyDown(event, handleNext)
							}
							additionalClasses={`${
								darkMode ? "" : "bg-transparent"
							}`}
						/>
					</div>
				</>
			) : (
				<>
					{isError && (
						<Toaster
							clearMessage={() => setIsError(false)}
							message="An error occurred with your user name or password. Please try again."
							additionalClasses="mb-2 text-center bottom-[160px] left-0 right-0"
							color="red-500"
						/>
					)}
					{apiError && (
						<Toaster
							clearMessage={() => setIsError(false)}
							message="We are experiencing an unexpected difficulty. Please try again in 15 minutes."
							additionalClasses="mb-2 text-center bottom-[160px] left-0 right-0"
							color="red-500"
						/>
					)}
					<div className="w-2/3 mx-auto">
						<Input
							firstIcon={
								<FontAwesomeIcon icon={faAt as IconProp} />
							}
							value={email}
							placeholder="Email *"
							type="email"
							id="email"
							onChange={(event) => setEmail(event.target.value)}
							label={""}
							required={false}
							name={"userEmail"}
							isValid={isValidEmail}
							disabled
						/>
						<div className="relative mt-4 mb-4">
							<PasswordInput
								id="password"
								value={password}
								placeholder="Password *"
								onChange={(event) =>
									setPassword(event.target.value)
								}
								required={true}
								name="userPassword"
								isValid={!isError}
								onKeyDown={(event) =>
									handleKeyDown(event, handleSubmit)
								}
								additionalClasses="bg-transparent"
							/>
						</div>
						<div className="flex justify-between gap-2 mt-4">
							<label className="flex items-center gap-2 cursor-pointer">
								<input
									type="checkbox"
									checked={isChecked}
									onChange={handleRememberMeCheckbox}
									className="form-checkbox h-4 w-4 text-blue-600"
								/>
								<span className="text-[18px] text-gray-700">
									Remember me
								</span>
							</label>
							<span className="text-primary hover:text-primaryLightHover hover:underline hover:decoration-solid">
								<Link to="/forgot-password">
									Forgot Password
								</Link>
							</span>
						</div>
					</div>
				</>
			)}
			{showEmailInput ? (
				<div className="flex items-center justify-center mt-8">
					<BaseButton
						icon={<FontAwesomeIcon icon={faRight as IconProp} />}
						type="button"
						text="Next"
						iconClasses="text-white"
						fontSize="text-xl"
						fontColor="text-white"
						iconOrder="last"
						shape="rounded-full"
						additionalClasses="bg-primarySlightLighter hover:bg-primaryDefaultHover px-4 py-2 gap-2 border-none"
						onClick={() => handleNext()}
					/>
				</div>
			) : (
				<div className="w-2/3 flex justify-between mt-4">
					<div>
						<BaseButton
							icon={<FontAwesomeIcon icon={faLeft as IconProp} />}
							type="button"
							iconOrder="first"
							onClick={handleGoBack}
							text="Back"
							bgColor="white"
							fontFamily="font-omnes-regular"
							fontColor="text-primary"
							iconClasses="text-primary"
							shape="rounded-full"
							borderColor="border-primary border-1"
							additionalClasses="hover:bg-primaryVeryLight mt-4 px-4 py-2 gap-2"
						/>
					</div>
					<div>
						<BaseButton
							icon={
								<FontAwesomeIcon
									icon={faRightToBracket as IconProp}
								/>
							}
							type="button"
							text="Log In"
							fontColor="text-white"
							iconClasses="text-white"
							iconOrder="last"
							shape="rounded-full"
							fontFamily="font-omnes-regular"
							additionalClasses="bg-primarySlightLighter hover:bg-primaryDefaultHover mt-4 px-4 py-2 gap-2 border-none"
							onClick={(event) =>
								handleSubmit(
									event as unknown as FormEvent<HTMLFormElement>
								)
							}
						/>
					</div>
				</div>
			)}
		</form>
	);
};

export default LoginForm;
