import { togaApiRequest } from "../../../api/toga";
import { ExtendedOptionsType } from "../../Items/api/itemsApi";

// This is the API call to get the Shipment details
export async function getShipmentDetails(shipmentUuid: string) {
	let options: ExtendedOptionsType = {
		where: {
			and: [{ uuid: { "=": shipmentUuid } }],
		},
	};
	let response = await togaApiRequest(
		"GET",
		"/item-fulfillments",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log(
			"Unable to get details for order with UUID: ",
			shipmentUuid
		);
		return;
	}
	return response.data;
}

export async function getShippingCarriers() {
	let options: ExtendedOptionsType = {
		fields: [
			"uuid",
			"name",
			"urlLogo",
			"shippingMethods.name",
			"shippingMethods.uuid",
			"shippingCarrierAccountNumbers.uuid",
			"shippingCarrierAccountNumbers.accountNumber",
			"shippingCarrierAccountNumbers.name",
		],
	};
	let response = await togaApiRequest(
		"GET",
		"/shipping-carriers",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log("Unable to get Shipping Carriers");
		return;
	}
	return response.data;
}

// This is the API call to get the order details
export async function getShipmentUnits(shipmentUuid: string) {
	let options: ExtendedOptionsType = {
		join: [
			{
				SalesOrderItems: {
					"SalesOrderItems.id": "Units.itemId",
				},
			},
		],
		ojoin: [
			{
				ItemFulfillmentItemUnits: {
					"ItemFulfillmentItemUnits.unitId": "Units.id",
				},
			},
		],
		where: {
			and: [
				{
					"itemFulfillmentItemUnits.id": { eq: null },
				},
				{
					"SalesOrderItems.uuid": { eq: "Item.uuid" },
				},
			],
		},
	};
	let response = await togaApiRequest(
		"GET",
		"/item-fulfillment-item-units",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log(
			"Unable to get details for order with UUID: ",
			shipmentUuid
		);
		return;
	}
	return response.data;
}

// This is the API call to get the States
export async function getStates() {
	let options: ExtendedOptionsType = {
		fields: ["uuid", "name", "code"],
		recordsPerPage: 100,
	};
	let response = await togaApiRequest("GET", "/states", null, options);

	if (!response.isSuccess) {
		console.log("Unable to get Shipping Carriers");
		return;
	}
	return response.data;
}

//This Api call is to get the countries
export async function getCountries() {
	let options: ExtendedOptionsType = {
		fields: ["uuid", "name"],
	};
	let response = await togaApiRequest("GET", "/countries", null, options);
	if (!response.isSuccess) {
		console.log("Unable to get Shipping Carriers");
		return;
	}
	return response.data;
}

//This Api call to get the address
export async function getAddress(internalId: string) {
	let options: ExtendedOptionsType = {
		fields: [
			"shipToAddress.state.code",
			"shipToAddress.state.name",
			"shipToAddress.state.uuid",
			"shipToAddress.addressee",
			"shipToAddress.attention",
			"shipToAddress.line1",
			"shipToAddress.line2",
			"shipToAddress.city",
			"shipToAddress.zip",
			"shipToAddress.uuid",
			"shipToAddress.state.country.name",
			"shipToAddress.state.country.uuid",
			"shipToAddress.state.country.code",
			"shipToAddress.phoneNumber",
		],
		where: {
			and: [{ c_netsuiteInternalSalesOrderId: { "=": internalId } }],
		},
	};
	let response = await togaApiRequest("GET", "/sales-orders", null, options);

	if (!response.isSuccess) {
		console.log("Unable to get address details");
		return;
	}
	return response.data;
}

//This Api call is to get the serialNumbers based on partNumber
export async function getSerialNumbers(partNumber: string) {
	let options: ExtendedOptionsType = {
		fields: ["uuid", "serialNumber"],
		join: [
			{
				Items: {
					"Items.id": "Units.itemId",
				},
			},
		],
		ojoin: [
			{
				ItemFulfillmentItemUnits: {
					"ItemFulfillmentItemUnits.unitId": "Units.id",
				},
			},
		],
		where: {
			and: [
				{ partNumber: { "=": partNumber } },
				{ "ItemFulfillmentItemUnits.unitId": { eq: null } },
			],
		},
	};
	let response = await togaApiRequest("GET", "/units", null, options);

	if (!response.isSuccess) {
		console.log("Unable to get Serial Numbers");
		return;
	}
	return response.data;
}

// Post API call to save shipment in 2.0 Database
export async function saveShipment(data: any) {
	// Destructure to exclude itemFulfillmentPackages
	const { itemFulfillmentPackages, ...shipmentsData } = data;

	let response = await togaApiRequest(
		"POST",
		"/item-fulfillments",
		shipmentsData
	);
	if (!response.isSuccess) {
		console.log("Unable to save shipment");
		return;
	}

	// Save itemFulfillmentPackages
	const itemFulfillmentUuid = response.data.itemFulfillments.uuid;
	const trackingNumber = itemFulfillmentPackages[0].trackingNumber;
	//Save the tracking Number details
	response = await togaApiRequest(
		"POST",
		"/tracking-numbers",
		trackingNumber
	);
	if (!response.isSuccess) {
		console.log("Unable to save tracking number");
		return;
	}
	const trackingNumberUuid = response.data.trackingNumbers.uuid;

	const itemFulfillmentPackagesData = {
		itemFulfillment: {
			uuid: itemFulfillmentUuid,
		},
		trackingNumber: {
			uuid: trackingNumberUuid,
		},
	};

	// Save the itemFulfillmentPackages
	response = await togaApiRequest(
		"POST",
		"/item-fulfillment-packages",
		itemFulfillmentPackagesData
	);
	if (!response.isSuccess) {
		console.log("Unable to save shipment");
		return;
	}

	//Update the fulfillment back to netsuite
	const netsuiteResponse = await togaApiRequest(
		"GET",
		"/item-fulfillments/createNetsuiteItemFulfillment?itemFulfillmentUuid=" +
			itemFulfillmentUuid
	);

 
	return {
		status: 201,
		itemFulfillmentUuid: itemFulfillmentUuid,
		trackingNumberUuid: trackingNumberUuid,
	};
}

// Generate shipment Label for the shipment
export async function fulfillShipment(shipmentUuid: string) {
	let response = await togaApiRequest(
		"GET",
		"/item-fulfillments/fedexShipmentApi?itemFulfillmentUuid=" +
			shipmentUuid
	);
	if (!response.isSuccess) {
		console.log("Unable to save shipment");
		return;
	}

	return response.data;
}

export async function handleShipmentDownload(response: any, from?: string) {
	if (response?.itemFulfillments?.fedexShipmentApi) {
		try {
			const base64Pdf =
				response.itemFulfillments.fedexShipmentApi.pdfLabel;
			const trackingNumber =
				response.itemFulfillments.fedexShipmentApi.trackingNumber;

			// Convert base64 string to a Blob
			const byteCharacters = atob(base64Pdf); // decode base64
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: "application/pdf" });

			// Create a Blob URL for the PDF
			const url = window.URL.createObjectURL(blob);

			// Open the PDF in a new window/tab
			const newWindow = window.open(url);

			// Automatically trigger the print dialog after the PDF has been loaded
			if (newWindow) {
				newWindow.onload = function () {
					newWindow.focus(); // Ensure the new tab is focused
					newWindow.print(); // Trigger the print dialog
				};
			} else {
				alert("Please allow popups for this site to print the label.");
			}

			// Clean up the Blob URL after some delay to ensure the print dialog works
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
			}, 1000); // 1-second delay to ensure the URL is still available
		} catch (error) {
			console.error("Error handling shipment download:", error);
		}
	} else {
		console.log("Error fetching shipment data", response);
	}
}

//Get Total Item Fulfillment Count for this sales order
export async function getItemFulfillmentCount(internalId: string) {
	let options: ExtendedOptionsType = {
		fields: ["uuid"],
		join: [
			{
				SalesOrders: {
					"ItemFulfillments.salesOrderId": "SalesOrders.id",
				},
			},
		],
		where: {
			and: [{ c_netsuiteInternalSalesOrderId: { "=": internalId } }],
		},
	};
	let response = await togaApiRequest(
		"GET",
		"/item-fulfillments",
		null,
		options
	);
	const recordCount = response.meta.totalRecordCount + 1;
	return recordCount;
}

//Validate Serial Number
export async function validateSerialNumber(
	serialNumber: string,
	partNumber: string,
	uuid: string
) {
	if (serialNumber === "") {
		return {
			serialNumber: serialNumber,
			partNumber: partNumber,
			isValid: false,
			uuid: uuid,
		};
	}

	let queryParameters =
		"?serialNumber=" + serialNumber + "&partNumber=" + partNumber;
	let response = await togaApiRequest(
		"GET",
		"/units/validateSerialNumber" + queryParameters,
		null
	);
	if (!response.isSuccess) {
 		return {
			serialNumber: serialNumber,
			partNumber: partNumber,
			isValid: false,
			uuid: uuid,
		};
	}
	return {
		serialNumber: serialNumber,
		partNumber: partNumber,
		isValid: response.data.units.validateSerialNumber,
		uuid: uuid,
	};
}
