// src/components/AuthLayout.tsx

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ToasterList } from "../misc";
import { Footer, Header } from "../layout";
import { useToaster } from "../../hooks/useToasterContext";

interface AuthLayoutProps {
	showFooter: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ showFooter }) => {
	const { toasters } = useToaster();

	return (
		<div className="m-0 h-full w-full relative overflow-hidden bg-gray-1">
			<ToasterList messages={toasters} />
			<Header />
			<div className="max-h-full h-[calc(100vh-80px)] mt-20 pb-6">
				<Outlet />
			</div>
			{/* {showFooter ? (
				<Footer companyName={"Agilant Solutions Inc."} />
			) : null} */}
		</div>
	);
};

export default AuthLayout;
