import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SupplyPortal from "./SupplyPortal";
import SupplyToaster from "./SupplyToaster";

interface Toaster {
	isOpen: boolean;
	message: string;
	status: string;
}

interface ToasterWithId extends Toaster {
	id: string;
}

interface ToasterListProps {
	messages: Toaster[];
}

const ToasterList: React.FC<ToasterListProps> = ({ messages }) => {
	const [visibleMessages, setVisibleMessages] = useState<ToasterWithId[]>([]);

	useEffect(() => {
		const newVisibleMessages = messages.map((message) => ({
			...message,
			id: `${Date.now()}-${Math.random()}`,
		}));

		setVisibleMessages(newVisibleMessages);
	}, [messages]);

	return (
		<SupplyPortal containerId="portal-root">
			<AnimatePresence>
				{visibleMessages.length !== 0 && (
					<motion.div
						className="absolute top-12 right-20 z-[9999] bg-transparent"
						initial={{ opacity: 0, translateY: -20 }}
						animate={{ opacity: 1, translateY: 40 }}
						exit={{ opacity: 0, translateY: -20 }}
						transition={{ duration: 0.3, ease: "easeOut" }}
					>
						{visibleMessages.map((message) => (
							<div
								className={"relative z-[9999]"}
								key={`toaster-${message.id}`}
							>
								<SupplyToaster
									message={message.message}
									classNames={""}
									status={message.status}
								/>
							</div>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</SupplyPortal>
	);
};

export default ToasterList;
