// GENERAL
import React, { useState, useEffect } from "react";
import { useFormState, useFormContext } from "react-hook-form";

// COMPONENTS
import SerialNumbersModal from "../../../view/modals/SerialNumbersModal";
import ExpandableCellContent from "../../../../../components/ui/Tables/ExpandableCellContent";
import BaseInput from "../../../../../components/ui/BaseInput/BaseInput";
import BaseDisabledInput from "../../../../../components/ui/BaseDisabledInput";
import { BaseText } from "../../../../../components/ui";
import { ImageComponent } from "../../../../../components/layout";
import { BasicTable } from "../../../../../components/ui/Tables";
import { SupplyFormGuardrail } from "../../../../../components/misc";
import { BaseButton } from "../../../../../components/ui";
import { FormValidationLoadingMessage } from "../../../../../components/misc";
import { BaseToolTip } from "../../../../../components/ui";

// TYPES, FIELDS, UTILS, HOOKS, ASSETS
import {
	SelectedShipmentItemsTableProps,
	ShipmentOrderSelectedItemTypes,
	FrontEndValidationResults,
	FrontEndValidationResult,
	NetSuiteValidationResult,
	AllValidationResults,
	ValidationResult,
	CheckSerialNumbers,
	SerialNumbers,
	CapturedRowDetails,
} from "../../types";
import {
	determineValidationResults,
	handleRemoveItemFromQuantity,
	renderValidateOrApplyButton,
	formatSerialNumbers,
	getDynamicDefaultValue,
	renderTableValidationMessage,
	getSerialNumbers,
	handleNetsuiteValidationUpdates,
} from "./utils";
import placeholderImage from "../../../../../assets/empty-image.png";
import dummyFields from "./DUMMYSHIPMENTITEMSTABLEFIELDS.json";
import dummySerialNumberModalFields from "./DUMMYSERIALNUMBERMODALFIELDS.json";
import { getFontAwesomeIcon } from "../../../../../utils";
import { validateSerialNumber } from "../../../api/UpdateShipmentApi";

const SelectedShipmentItemsTable: React.FC<SelectedShipmentItemsTableProps> = ({
	data,
	control,
	updateQuantity,
}) => {
	const { setValue, getValues, reset, setError, clearErrors } =
		useFormContext();
	const [isGuardrailOpen, setIsGuardrailOpen] = useState(false);
	const { errors } = useFormState({ control });
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedPartNumber, setSelectedPartNumber] = useState<string>("");
	const [textareaValue, setTextareaValue] = useState("");
	const [serialNumbers, setSerialNumbers] = useState<{
		[key: string]: string[];
	}>({});
	const [savedSerialNumbers, setSavedSerialNumbers] = useState<{
		[key: string]: string[];
	}>({});
	const [frontEndValidationResults, setFrontEndValidationResults] =
		useState<FrontEndValidationResults>([]);
	const [netsuiteValidationResults, setNetsuiteValidationResults] = useState<
		NetSuiteValidationResult[]
	>([]);
	const [allValidationResults, setAllValidationResults] = useState<
		NetSuiteValidationResult[]
	>([]);
	const [isValidating, setIsValidating] = useState<boolean>(false);
	const [netsuiteIsValidating, setNetsuiteIsValidating] =
		useState<boolean>(false);
	const [applySerialNumbers, setApplySerialNumbers] =
		useState<boolean>(false);
	const [capturedRowDetails, setCapturedRowDetails] =
		useState<CapturedRowDetails>();

	/////////////////////////////////////////////// STYLES ///////////////////////////////////////////

	// Selected Items Table Classes
	const descriptionColumnClasses = `flex items-center justify-between text-sm font-omnes-regular font-light break-words`;
	const numberColumnsClasses = `flex items-center justify-end text-sm relative font-omnes-regular font-light min-w-20`;
	const partNumberColumnClasses = `flex items-center justify-start w-fit min-w-40`;

	// Serial Number Modal Classes
	const textAreaClasses = `w-full border-1 border-navy-250 rounded-sm min-h-36 px-2 py-1 my-5`;
	const serialNumberTableContainerClasses = `flex w-full justify-between items-center pb-5 border-b-1 border-b-navy-200 mb-5`;
	const validIconClasses = `flex items-center text-mint-600 text-xl pr-1 font-omnes-medium`;
	const notValidIconClasses =
		"flex items-center text-crimson-700 text-xl pr-1";
	const guardrailBackgroundClasses = `fixed top-0 left-0 h-screen w-screen bg-black opacity-30 z-[9915]`;

	/////////////////////////////////////// SERIAL NUMBER MODAL FORM /////////////////////////////////

	const openModal = (partNumber: string) => {
		setSelectedPartNumber(partNumber);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedPartNumber("");
		setIsValidating(false);
	};

	const toggleModal = () => {
		if (isModalOpen) {
			closeModal();
		} else {
			openModal(selectedPartNumber);
		}
	};
	///////////////// FRONT END VALIDATION FOR SERIAL NUMBER MODAL /////////////////
	const handleFrontEndVerifySerialNumbers = () => {
		// If textarea is empty and no part number is provided, stop validation
		// this is for front end validation within the serial number modal
		if (textareaValue.trim() === "") {
			setIsValidating(false);
			return;
		}

		setIsValidating(true);

		// Simulate async operation with setTimeout
		setTimeout(() => {
			let serialNumbersArray: string[];

			// formats serial numbers from text area in serial number modal
			serialNumbersArray = formatSerialNumbers(textareaValue);

			const findPartNumberData = data.find(
				(item: { [key: string]: any }) => {
					return item.uuid === selectedPartNumber;
				}
			);

			let uuid = findPartNumberData?.uuid;
			let partNumber = findPartNumberData?.partNumber;
			// updates serial numbers state with current part number and serial numbers (?why do i do this here? )
			setSerialNumbers((prevSerialNumbers) => ({
				...prevSerialNumbers,
				[uuid]: serialNumbersArray as string[],
			}));

			const uniqueSerialNumbers = new Set<string>();
			const duplicates = new Set<string>();
			const invalidCharacters = /[!@#$%*?()]/;

			// checks for duplicates and invalid characters in the serial numbers
			// front end validation only via regex
			let currentPartNumberResults = serialNumbersArray.map(
				(serialNumber: string) => {
					let hasDuplicates = false;
					let hasInvalidCharacters = false;

					if (invalidCharacters.test(serialNumber)) {
						hasInvalidCharacters = true;
					}
					if (uniqueSerialNumbers.has(serialNumber)) {
						duplicates.add(serialNumber);
						hasDuplicates = true;
					} else {
						uniqueSerialNumbers.add(serialNumber);
					}

					return {
						serialNumber: serialNumber,
						partNumber: partNumber,
						hasDuplicates: hasDuplicates,
						hasInvalidCharacters: hasInvalidCharacters,
						uuid: uuid,
					};
				}
			);

			let otherPartNumberResults;

			// If there are previous frontEndValidationResults results, filter out the current part number
			if (frontEndValidationResults.length > 0) {
				otherPartNumberResults = frontEndValidationResults.filter(
					(result) => {
						return (
							result.partNumber !== findPartNumberData.partNumber
						);
					}
				);

				// Update validation results with new and previous results
				setFrontEndValidationResults([
					...otherPartNumberResults,
					...currentPartNumberResults,
				]);
			} else {
				// If no previous results, just set the new results
				setFrontEndValidationResults((prevSerialNumbers) => [
					...prevSerialNumbers,
					...currentPartNumberResults,
				]);
			}

			setIsValidating(false);
		}, 500);
	};

	//////////// HELPER FUNCTION FOR NETSUITE VALIDATION ASYNC API CALL ////////////
	const verifySerialNumber = (
		serialNumber: string,
		partNumber: string,
		uuid: string
	): Promise<NetSuiteValidationResult> => {
		return new Promise(async (resolve, reject) => {
			try {
				const isValid = await validateSerialNumber(
					serialNumber,
					partNumber,
					uuid
				);
				resolve(isValid);
			} catch (error) {
				reject(error);
			}
		});
	};

	//////////// INVOKES NETSUITE ASYNC API CALL, FORMATS RESPONSE ////////////

	const handleNetsuiteVerification = async (
		singleInput?: boolean, // single input is true if validating outside of the modal
		partNumber?: string, // part number is passed through from single input
		uuid?: string // uuid is passed through from single input
	) => {
		setNetsuiteIsValidating(true);

		let serialNumbersArray: string[] = [];
		let currentPartNumber: string;

		const findPartNumberData = data.find((item: { [key: string]: any }) => {
			return item.uuid === selectedPartNumber;
		});

		let itemUuid = findPartNumberData?.uuid || uuid;
		// finds and formats serial numbers for current part number
		if (
			!singleInput &&
			selectedPartNumber &&
			findPartNumberData?.partNumber
		) {
			serialNumbersArray = formatSerialNumbers(textareaValue);
			currentPartNumber = findPartNumberData?.partNumber;
		} else if (singleInput && partNumber && uuid) {
			let valueKey = `partNumber: ${uuid}`;
			const serialNumber = getValues(valueKey as string);
			serialNumbersArray = [serialNumber];
			currentPartNumber = partNumber;
		}

		// set the serial numbers to state correctly (i don't think i'm actually using this)
		setSerialNumbers((prevSerialNumbers) => ({
			...prevSerialNumbers,
			[itemUuid]: serialNumbersArray,
		}));

		// Retrieve the serial numbers from the updated state
		const serialsToVerify = serialNumbersArray; // Use serialNumbersArray instead of serialNumbers[selectedPartNumber]

		// Map over the serials and return promises from verifySerialNumber
		const validationPromises = serialsToVerify.map((serial: string) => {
			// Return the promise from verifySerialNumber
			return verifySerialNumber(serial, currentPartNumber, itemUuid);
		});

		// Await all promises
		const results = await Promise.all(validationPromises);
		// filters out previous validation results from other part numbers to keep these unaffected
		const updateValidationResults = netsuiteValidationResults.filter(
			(result) => {
				return result.partNumber !== currentPartNumber;
			}
		);

		// updates netsuite validation results with new results,
		// removes old results for the part number, and adds new results
		setNetsuiteValidationResults(() => [
			...updateValidationResults, // previous validation results from other part numbers
			...results, // newly validated results
		]);

		setNetsuiteIsValidating(false);
		setApplySerialNumbers(false);
	};

	// setting text area value to the serial numbers for
	// whatever the selected part number number is
	useEffect(() => {
		if (selectedPartNumber && serialNumbers[selectedPartNumber]) {
			setTextareaValue(serialNumbers[selectedPartNumber].join("\n"));
		} else {
			setTextareaValue("");
		}
	}, [serialNumbers, selectedPartNumber]);

	const handleSerialNumberModalSave = () => {
		setSerialNumbers(serialNumbers);
		setSavedSerialNumbers(serialNumbers);

		if (netsuiteValidationResults.length > 0) {
			const validationResultsReturn = determineValidationResults(
				frontEndValidationResults as FrontEndValidationResults,
				netsuiteValidationResults,
				selectedPartNumber
			);

			const newValidationResult = {
				partNumber: selectedPartNumber,
				isFrontEndValid: validationResultsReturn.isFrontEndValid,
				netSuiteValidationResult:
					validationResultsReturn.netSuiteValidationResult,
			};

			// updating validation results with newValidationResult
			setAllValidationResults((prevResults) => {
				const resultIndex = prevResults.findIndex(
					(result) => result.partNumber === selectedPartNumber
				);

				if (resultIndex !== -1) {
					const updatedResults = [...prevResults];
					updatedResults[resultIndex] =
						newValidationResult as AllValidationResults;
					return updatedResults;
				} else {
					return [...prevResults, newValidationResult];
				}
			});
		}
		setIsModalOpen(false);
	};

	const handleSerialNumberModalCancel = () => {
		if (selectedPartNumber in savedSerialNumbers) {
			closeModal();
		} else {
			// removes partNumber from serial number state
			const updatedSerialNumbers = { ...serialNumbers };
			delete updatedSerialNumbers[selectedPartNumber];
			setSerialNumbers(updatedSerialNumbers);

			// Remove the values for partNumber: selectedPartNumber in the form state
			let serialNumbersFormValues = `partNumber: ${selectedPartNumber}`;

			// Filter out the objects with partNumber equal to selectedPartNumber
			const updatedValidationResults = netsuiteValidationResults.filter(
				(result) => result.uuid !== selectedPartNumber
			);
			setNetsuiteValidationResults(updatedValidationResults);
			setValue(serialNumbersFormValues, undefined);
			clearErrors(serialNumbersFormValues);
			setTextareaValue("");
			setFrontEndValidationResults([]);
			setApplySerialNumbers(false);
			closeModal();
		}
	};

	const handleSingleInputVerification = (
		singleInput: boolean,
		partNumber: string,
		uuid: string
	) => {
		handleNetsuiteVerification(singleInput, partNumber, uuid);
	};

	// serial number modal: helper function to invoke handleNetsuiteVerification from serial number modal
	const handleApplySerialNumbers = () => {
		setApplySerialNumbers(true);
		handleNetsuiteVerification();
	};

	// serial number modal: captures row details to delete item from total quantity
	const handleCaptureRowDetails = (
		partNumber: string,
		index: number,
		serialNumber: string
	) => {
		setCapturedRowDetails({ partNumber, index, serialNumber });
		setIsGuardrailOpen(true);
	};

	useEffect(() => {
		if (
			applySerialNumbers &&
			serialNumbers[selectedPartNumber]?.length > 0
		) {
			const selectedPartNumberData = data.find(
				(order: ShipmentOrderSelectedItemTypes) =>
					order.uuid === selectedPartNumber
			);

			if (selectedPartNumberData) {
				serialNumbers[selectedPartNumber].forEach(
					(serialNumber, index) => {
						setValue(
							`partNumber: ${selectedPartNumberData.uuid}.index:${index}`,
							serialNumber
						);
					}
				);
			}
		}
	}, [serialNumbers, selectedPartNumber, applySerialNumbers, setValue, data]);

	// handles live validation for serial numbers using netsuite validation results
	useEffect(() => {
		netsuiteValidationResults.forEach((result) => {
			const { partNumber, serialNumber, uuid } = result;
			const partNumberField = `partNumber: ${uuid}`;
			const selectedItem = data.find(
				(item: { [key: string]: any }) => item.partNumber === partNumber
			);
			const serialNumberEntries = Object.entries(getValues()).filter(
				([key, value]) => key.startsWith(partNumberField)
			);

			if (serialNumberEntries.length > 0) {
				const foundSerialNumberEntry = getSerialNumbers(
					partNumberField,
					getValues,
					serialNumber
				);

				handleNetsuiteValidationUpdates(
					result,
					partNumberField,
					selectedItem,
					isModalOpen,
					clearErrors,
					setError,
					foundSerialNumberEntry
				);
			}
		});
	}, [
		netsuiteValidationResults,
		netsuiteIsValidating,
		errors,
		isModalOpen,
		serialNumbers,
	]);

	/////////////////////////////////////// ITEMS TABLE FORMATTING ///////////////////////////////////

	const tableData = React.useMemo(() => {
		const result =
			data &&
			data.length > 0 &&
			serialNumbers &&
			data.map((order: ShipmentOrderSelectedItemTypes, index: number) => {
				const partNumber = order?.itemData?.item?.partNumber || "—";
				const quantity = Number(order?.quantity) || 0;
				const sku = order?.itemData?.item?.sku || "—";
				const upc = order?.itemData?.item?.upc || "—";
				const uuid = order?.uuid || "—";
				const orderQuantity = order?.quantity;
				const manufacturer =
					order?.itemData.item?.manufacturer?.name || "—";
				const validationResult = allValidationResults.find((result) => {
					return result.partNumber === uuid;
				});
				const getValidationResult = () => {
					const netsuiteValidationResult =
						netsuiteValidationResults.find((result) => {
							return result.uuid === uuid;
						});
					return netsuiteValidationResult?.isValid;
				};

				const validationResultValue = getValidationResult();
				const errorMessage =
					errors?.[`partNumber: ${uuid}`]?.message || "";

				return {
					col1: (
						<div className="flex justify-end">
							<p className="flex justify-end w-11">{index + 1}</p>
						</div>
					),
					col2: (
						<div className={partNumberColumnClasses}>
							<ImageComponent
								imageUrl={placeholderImage}
								altText=""
								className=""
								width={20}
								height={10}
							/>
							<div className="pl-2">{partNumber}</div>
						</div>
					),
					col3: <div className={`min-w-150px`}>{sku}</div>,
					col4: <div className={`min-w-150px`}>{upc}</div>,
					col5: <div className={`min-w-150px`}>{manufacturer}</div>,
					col6: (
						<div>
							<ExpandableCellContent
								columnData={
									order?.itemData.item?.description || "—"
								}
								columnDataLength={55}
								onHoverColumnDataLength={40}
								minWidth="w-[340px]"
								notShortenedClasses={descriptionColumnClasses}
							/>
						</div>
					),
					col7: (
						<div className={`${numberColumnsClasses}`}>
							{orderQuantity}
						</div>
					),
					col8: (
						<div className="flex flex-col items-center justify-center w-293px">
							{order?.itemData?.item?.inventoryType ===
							"NON_SERIALIZED" ? (
								<div className="flex justify-between">
									<BaseDisabledInput
										key={order?.uuid}
										field={
											dummyFields
												.DUMMYSHIPMENTITEMSTABLEFIELDS[0]
										}
										inputContainerClasses="flex items-center w-56 justify-center pb-0"
										disabledInputClasses="rounded w-[198px] text-sm bg-navy-200 px-2"
										text="Non-serialized"
										inputTextSize="text-sm text-navy-400"
									/>
								</div>
							) : quantity > 1 ? (
								<>
									<div className="flex justify-between">
										<BaseButton
											text={
												serialNumbers[uuid]?.length > 0
													? "Edit Serial Numbers"
													: "Add Serial #s"
											}
											bgColor="bg-primary"
											hoverBackground="hover:bg-primaryHover"
											fontColor="text-white"
											additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4`"
											shape="rounded-full"
											as="button"
											fontFamily="font-omnes-medium"
											buttonSize="h-7 w-[198px]"
											icon={
												serialNumbers[uuid]?.length > 0
													? getFontAwesomeIcon(
															"pen",
															"regular"
													  )
													: getFontAwesomeIcon(
															"clipboard",
															"regular"
													  )
											}
											iconClasses="pr-2 text-xs text-white"
											onClick={() =>
												openModal(order?.uuid)
											}
											fontSize="text-sm"
										/>
										{renderTableValidationMessage(
											validationResult as ValidationResult,
											serialNumbers as SerialNumbers,
											uuid
										)}
									</div>
									{errorMessage && (
										<div className="w-full flex items-start pt-1 pl-4">
											<BaseText
												text={errorMessage as string}
												size={"text-xs"}
												color={"text-crimson-700"}
												tag={"span"}
												fontFamily={""}
											/>
										</div>
									)}
								</>
							) : (
								<div className="flex justify-between w-60">
									<div className="flex items-center justify-center pl-2 w-90%">
										<BaseInput
											key={order?.uuid}
											field={
												dummyFields
													.DUMMYSHIPMENTITEMSTABLEFIELDS[0]
											}
											inputContainerClasses="flex items-center w-[198px] justify-center pb-0 mr-2.5"
											inputTextSize="text-sm"
											uuid={`partNumber: ${order?.uuid}`}
											hasParentControl={control}
											isArrayValue={false}
											onBlur={() => {
												handleSingleInputVerification(
													true,
													partNumber,
													`${order?.uuid}`
												);
											}}
										/>
									</div>

									<div className="flex items-center justify-center pl-1 w-10">
										{(isValidating ||
											netsuiteIsValidating ||
											(netsuiteValidationResults &&
												netsuiteValidationResults.length >
													0)) &&
										validationResultValue !== undefined ? (
											<FormValidationLoadingMessage
												isValidating={
													netsuiteIsValidating ||
													isValidating
												}
												isValid={
													validationResultValue as boolean
												}
												loadingMessage=""
												loadingMessageClasses=""
												loadingIconClasses="size-5"
												loadingContainerClasses="flex items-center"
												loadingMotionContainerClasses=""
												validMessage=""
												notValidMessage=""
												notValidMessageClasses="text-crimson-700 font-omnes-medium"
												validMessageClasses="text-mint-800"
												validIconClasses={
													validIconClasses
												}
												notValidIconClasses={
													notValidIconClasses
												}
												validIcon={getFontAwesomeIcon(
													"circleCheck",
													"solid"
												)}
												notValidIcon={getFontAwesomeIcon(
													"circleXmark",
													"solid"
												)}
												placeholderMessage=""
												placeholderMessageClasses="px-1"
											/>
										) : (
											<></>
										)}
									</div>
								</div>
							)}
						</div>
					),
				};
			});

		return result;
	}, [
		data,
		serialNumbers,
		selectedPartNumber,
		errors,
		netsuiteValidationResults,
		frontEndValidationResults,
		applySerialNumbers,
		allValidationResults,
		netsuiteIsValidating,
		isValidating,
	]);

	const defaultHeaderData = React.useMemo(() => {
		return [
			{ id: "col1", Header: "Line", headerClasses: "text-right" },
			{ id: "col2", Header: "Part Number", headerClasses: "text-left" },
			{ id: "col3", Header: "SKU", headerClasses: "text-left" },
			{ id: "col4", Header: "UPC", headerClasses: "text-left" },
			{ id: "col5", Header: "Manufacturer", headerClasses: "text-left" },
			{ id: "col6", Header: "Description", headerClasses: "text-left" },
			{ id: "col7", Header: "Quantity", headerClasses: "text-right" },
			{ id: "col8", Header: "Serial Number", headerClasses: "text-left" },
		];
	}, [data]);

	/////////////////////////////////// SERIAL NUMBER TABLE FORMATTING ///////////////////////////////

	const serialNumberTableData = React.useMemo(() => {
		const selectedPartNumberData = data.find(
			(order: ShipmentOrderSelectedItemTypes) => {
				return order.uuid === selectedPartNumber;
			}
		);

		const getValidationResult = () => {
			const netsuiteValidationResult = netsuiteValidationResults?.filter(
				(result) => {
					return result.uuid === selectedPartNumber;
				}
			);
			return netsuiteValidationResult;
		};

		const validationResultValue = getValidationResult();
		if (selectedPartNumberData) {
			return Array.from(
				{ length: selectedPartNumberData.quantity },
				(_, index) => {
					let validationResult;
					if (
						netsuiteValidationResults &&
						validationResultValue.length !== 0
					) {
						validationResult = validationResultValue[index].isValid;
					}

					return {
						col1: (
							<div className="flex justify-end">
								<p className="flex justify-end w-11">
									{index + 1}
								</p>
							</div>
						),
						col2: (
							<div className="flex items-center justify-start w-fit min-w-96">
								<ImageComponent
									imageUrl={placeholderImage}
									altText="Sample Image"
									className=""
									width={20}
									height={10}
								/>
								<div className="pl-2">
									{selectedPartNumberData.partNumber}
								</div>
							</div>
						),
						col3: (
							<div className="flex justify-start min-w-60">
								<div className="flex items-center justify-center pl-2 w-90%">
									<BaseInput
										key={` ${selectedPartNumberData.uuid}-${index}`}
										field={
											dummySerialNumberModalFields
												.DUMMYSERIALNUMBERMODALFIELDS[0]
										}
										dataItem={
											selectedPartNumberData.itemData
										}
										inputContainerClasses="flex w-full pb-0"
										inputTextSize="text-sm"
										uuid={`partNumber: ${selectedPartNumberData.uuid}.index:${index}`}
										hasParentControl={control}
										dynamicDefaultValue={getDynamicDefaultValue(
											serialNumbers,
											selectedPartNumber,
											index,
											applySerialNumbers,
											getValues
										)}
										isArrayValue={true}
										onBlur={() => {
											const newValue = getValues(
												`partNumber: ${selectedPartNumberData.uuid}.index:${index}`
											);

											setSerialNumbers(
												(prevSerialNumbers) => {
													// Create a copy of the previous state
													const newSerialNumbers = {
														...prevSerialNumbers,
													};

													 

													// Ensure the selected part number exists in the state
													if (
														!newSerialNumbers[
															selectedPartNumber
														]
													) {
														newSerialNumbers[
															selectedPartNumber
														] = [];
													}

													// Update the specific index with the new value
													newSerialNumbers[
														selectedPartNumber
													][index] = newValue;

													return newSerialNumbers;
												}
											);
										}}
									/>
								</div>

								<div className="flex items-center justify-center pl-2">
									{((netsuiteValidationResults &&
										validationResultValue &&
										validationResultValue.length !== 0) ||
										netsuiteIsValidating) && (
										<FormValidationLoadingMessage
											isValidating={netsuiteIsValidating}
											isValid={
												validationResult as boolean
											}
											loadingMessage=""
											loadingMessageClasses=""
											loadingIconClasses="size-5"
											loadingContainerClasses="absolute top-[-10px]"
											loadingMotionContainerClasses="relative"
											validMessage=""
											notValidMessage=""
											notValidMessageClasses="text-crimson-700 font-omnes-medium"
											validMessageClasses="text-mint-800"
											validIconClasses={validIconClasses}
											notValidIconClasses={
												notValidIconClasses
											}
											validIcon={getFontAwesomeIcon(
												"circleCheck",
												"solid"
											)}
											notValidIcon={getFontAwesomeIcon(
												"circleXmark",
												"solid"
											)}
											placeholderMessage=""
											placeholderMessageClasses="px-1"
										/>
									)}
								</div>
							</div>
						),
						col4: (
							<div className="flex items-center justify-center max-w-10">
								<BaseToolTip
									toolTipPlacement="top"
									message="Delete"
									children={
										<BaseButton
											iconAriaLabel="Delete"
											buttonSize="h-7 w-7"
											hoverBackground="hover:bg-crimson-300 hover:border-crimson-700"
											borderColor="border-1 border-navy-200"
											additionalClasses="rounded-sm"
											as="button"
											iconClasses=" text-base p-1 text-crimson-700"
											onClick={() =>
												handleCaptureRowDetails(
													selectedPartNumberData.partNumber,
													index,
													getValues(
														`partNumber: ${selectedPartNumberData.partNumber}.index:${index}`
													)
												)
											}
											icon={getFontAwesomeIcon(
												"trash",
												"regular"
											)}
										/>
									}
								/>
							</div>
						),
					};
				}
			);
		}
		return [];
	}, [
		data,
		serialNumbers,
		errors,
		selectedPartNumber,
		applySerialNumbers,
		netsuiteValidationResults,
		isValidating,
		netsuiteIsValidating,
		textareaValue,
	]);

	const serialNumbersHeaderData = React.useMemo(() => {
		return [
			{ id: "col1", Header: "Line", headerClasses: "text-right" },
			{ id: "col2", Header: "Part Number", headerClasses: "text-left" },
			{ id: "col3", Header: "Serial Number", headerClasses: "text-left" },
			{ id: "col4", Header: "", headerClasses: "text-left" },
		];
	}, [data]);

	const serialNumberModalContent = React.useMemo(() => {
		let hasDuplicates = false;
		let hasInvalidCharacters = false;
		let isValid = false;

		const helperFrontendValidation = () => {
			handleFrontEndVerifySerialNumbers();
		};

		if (frontEndValidationResults && frontEndValidationResults.length > 0) {
			const checkForErrors = frontEndValidationResults.filter(
				(item: FrontEndValidationResult) =>
					item.uuid === selectedPartNumber
			);

			checkForErrors.forEach((item: CheckSerialNumbers) => {
				hasDuplicates = item.hasDuplicates;
				hasInvalidCharacters =
					hasInvalidCharacters || item.hasInvalidCharacters;
			});

			if (
				!hasDuplicates &&
				!hasInvalidCharacters &&
				checkForErrors.length > 0
			) {
				isValid = true;
			}
		}

		let notValidMessage = "";
		if (hasDuplicates) {
			notValidMessage = "There are duplicate serial numbers.";
		} else if (hasInvalidCharacters) {
			notValidMessage =
				"There are invalid characters in the serial numbers.";
		}

		return (
			<>
				<div className="flex flex-col h-[90%] justify-between">
					<div>
						<label
							className="sr-only"
							htmlFor="serialNumbersTextarea"
						>
							Paste serial numbers here. Acceptable separators
							include new lines, spaces, colons, and semi-colons.
						</label>
						<textarea
							id="serialNumbersTextarea"
							className={textAreaClasses}
							value={textareaValue}
							onChange={(e) => setTextareaValue(e.target.value)}
							placeholder="Paste serial numbers here. Acceptable separators include new lines, spaces, colons, and semi-colons."
						></textarea>
						<div className={serialNumberTableContainerClasses}>
							{(frontEndValidationResults &&
								frontEndValidationResults.length > 0) ||
							isValidating ? (
								<FormValidationLoadingMessage
									isValidating={isValidating}
									isValid={isValid}
									loadingMessage="Verifying..."
									loadingMessageClasses="pl-2 text-navy-500 font-medium"
									loadingIconClasses="size-5"
									loadingContainerClasses="flex items-center px-0"
									validMessage="Success!"
									notValidMessage={notValidMessage}
									notValidMessageClasses="text-crimson-700 font-omnes-medium"
									loadingMotionContainerClasses="flex items-center justify-start w-full"
									validMessageClasses="text-mint-800"
									validIconClasses={validIconClasses}
									notValidIconClasses={notValidIconClasses}
									validIcon={getFontAwesomeIcon(
										"circleCheck",
										"solid"
									)}
									notValidIcon={getFontAwesomeIcon(
										"circleXmark",
										"solid"
									)}
									placeholderMessage=""
									placeholderMessageClasses="px-1"
								/>
							) : (
								<div className=""></div>
							)}
							<div className="flex w-1/2 justify-end">
								{renderValidateOrApplyButton(
									isValidating,
									isValid,
									textareaValue,
									helperFrontendValidation,
									handleApplySerialNumbers,
									frontEndValidationResults
								)}
							</div>
						</div>
						<BasicTable
							data={serialNumberTableData}
							headers={serialNumbersHeaderData}
							hasHeaderSpan={false}
							tableBottomBorderClasses="border-b-1 border-navy-250"
							tableContainerWidth="w-full max-h-[500px] overflow-y-scroll"
							tableWidth="w-full table-auto"
							quantityLabelClasses="text-base mb-5"
							tableContainerHeight=""
							headerBackgroundColor="bg-navy-50"
							headerTextColor="text-black"
							headerFontSize="text-sm"
							headerBorderColor="border-navy-250"
							headerBorderWidth="border-1"
							headerFontFamily="font-omnes-regular"
							tableBorderColor="border-transparent"
							rowBackgroundColor="bg-white"
							rowHoverBackgroundColor=""
							rowBorderWidth="border-1"
							rowBorderColor="border-navy-200"
							rowHoverCursor=""
							cellFontSize="text-sm"
							cellPadding="px-2 py-3"
							noDataMessage="No Items Selected"
							showQuantity={false}
						/>
					</div>
				</div>
				<div className="flex items-end justify-end">
					<BaseButton
						text="Cancel"
						hoverBackground="hover:bg-primaryHoverLight"
						fontColor="text-primary"
						borderColor="border-1 border-primary"
						additionalClasses="py-1 px-3 mr-2.5 max-md:mb-4"
						shape="rounded-full"
						as="button"
						fontFamily="font-omnes-medium"
						buttonSize="h-7 w-28"
						fontSize="text-sm"
						onClick={handleSerialNumberModalCancel}
					/>
					<BaseButton
						text="Save"
						bgColor="bg-primary"
						hoverBackground="hover:bg-primaryHover"
						fontColor="text-white"
						additionalClasses="py-1 px-3 max-md:mb-4"
						shape="rounded-full"
						as="button"
						fontFamily="font-omnes-medium"
						buttonSize="h-7 w-28"
						onClick={handleSerialNumberModalSave}
						fontSize="text-sm"
					/>
				</div>
			</>
		);
	}, [
		data,
		serialNumbers,
		errors,
		selectedPartNumber,
		applySerialNumbers,
		netsuiteValidationResults,
		isValidating,
		netsuiteIsValidating,
		textareaValue,
		frontEndValidationResults,
	]);

	//////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		tableData && (
			<>
				<BasicTable
					data={tableData}
					headers={defaultHeaderData}
					hasHeaderSpan={false}
					tableBottomBorderClasses="border-b-1 border-navy-250"
					tableContainerWidth="w-full"
					tableWidth="w-full table-auto"
					quantityLabelClasses="text-base mb-5"
					tableContainerHeight=""
					headerBackgroundColor="bg-navy-50"
					headerTextColor="text-black"
					headerFontSize="text-sm"
					headerBorderColor="border-navy-250"
					headerBorderWidth="border-1"
					headerFontFamily="font-omnes-regular"
					tableBorderColor="border-transparent"
					rowBackgroundColor="bg-white"
					rowHoverBackgroundColor=""
					rowBorderWidth="border-1"
					rowBorderColor="border-navy-200"
					rowHoverCursor=""
					cellFontSize="text-sm"
					cellPadding="px-2 py-3"
					noDataMessage="No Items Selected"
				/>
				{isModalOpen && (
					<SerialNumbersModal
						isModalOpen={isModalOpen}
						toggleModal={toggleModal}
					>
						{serialNumberModalContent}
					</SerialNumbersModal>
				)}
				{isGuardrailOpen && (
					<>
						<div className={guardrailBackgroundClasses}>{""}</div>
						<SupplyFormGuardrail
							messages={{
								messageOne: `Are you sure you want to delete this item?`,
								messageTwo:
									"This will remove this item from the shipment.",
								isOpen: true,
							}}
							onSave={() => {
								handleRemoveItemFromQuantity(
									capturedRowDetails as CapturedRowDetails,
									getValues,
									reset,
									serialNumbers,
									setSerialNumbers,
									updateQuantity,
									setIsGuardrailOpen
								);
							}}
							buttonText={{
								centerButtonText: "Cancel",
								rightButtonText: "Delete Item",
							}}
							onCancel={() => setIsGuardrailOpen(false)}
							buttonContainerClasses="flex justify-end"
							rightButtonClasses="bg-crimson-700 text-white hover:bg-primary rounded-full px-3 font-omnes-medium text-sm"
							rightSideButtonContainerClasses="flex justify-between"
						/>
					</>
				)}
			</>
		)
	);
};

export default SelectedShipmentItemsTable;

