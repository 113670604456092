import React, { ReactNode } from "react";

interface BaseAddressTypes {
	additionalClasses?: string;
	addressLines?: string | string[];
	title?: ReactNode;
	contact?: string;
	textClasses?: string;
}

const BaseAddress: React.FC<BaseAddressTypes> = ({
	addressLines = [],
	additionalClasses,
	title,
	textClasses = "font-omnes-regular text-sm",
}) => {
	const addressArray = Array.isArray(addressLines)
		? addressLines
		: [addressLines];
	const validAddress = addressArray.map((line: string) =>
		!line || line === "N/A" || line.includes("null") ? "" : line
	);

	if (validAddress.length === 0) {
		return (
			<div className={`flex items-center ${additionalClasses}`}>
				<div className={`flex items-start pl-2 ${textClasses}`}>
					<p className="text-left font-omnes-medium">
						No Address Data Provided
					</p>
				</div>
			</div>
		);
	} else {
		return (
			<div className={`flex flex-col items-start ${additionalClasses}`}>
				{title && <>{title}</>}
				<div className={`flex flex-col items-start ${textClasses}`}>
					{validAddress.map((addressLine: string, index: number) => (
						<p key={index}>{addressLine}</p>
					))}
				</div>
			</div>
		);
	}
};

export default BaseAddress;
