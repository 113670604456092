import { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { getPrudentialOrders } from "../../pages/Orders/listing/api/OrdersApi";

function useExportableData(initialData = []) {
	const [data, setData] = useState(initialData);
	const [isLoading, setIsLoading] = useState(false);

	const exportToCSV = useCallback(async () => {
		setIsLoading(true);
		try {
			const transformedData = await getPrudentialOrders();
			const formattedData = transformedData.map((item) => ({
				"Purchase Order Number": item.PurchaseOrders.number,
				"Order Shipped": item.dateShipped,
				"Purchase Order Stage": item.PurchaseOrderStages.name,
				"Transmitted In Progress Update To Prudential":
					item.PurchaseOrders
						.c_dtTransmittedInProgressUpdateToPrudential,
				"Transmitted Order Shipped Update To Prudential":
					item.PurchaseOrders
						.c_dtTransmittedOrderShippedUpdateToPrudential,
				"Transmitted Order Closed Update To Prudential":
					item.PurchaseOrders
						.c_dtTransmittedOrderClosedUpdateToPrudential,
				"Sales Order Number": item.SalesOrders.number,
				"Sales Order Created": item.SalesOrders.dateOrder,
				"Ship To": `${item.Addresses.line1}${
					item.Addresses.line2 ? ", " + item.Addresses.line2 : ""
				}, ${item.Addresses.city}, ${item.States.code}, ${
					item.Addresses.zip
				}`,
				"RITM Number": item.ServiceRequests.c_ritmNumber,
				"REQ Number": item.ServiceRequests.c_reqNumber,
				"REQ Type": item.ServiceRequestTypes.name,
				"Tracking Number": item.TrackingNumbers.number
					? '"' + item.TrackingNumbers.number + '"'
					: "",
				"Tracking Number Status": item.TrackingNumbers.status,
				"Unit Name": item.Units.name,
				"Unit Serial Number": item.Units.serialNumber,
				"Item Part Number": item.Items.partNumber,
				"Item Description": item.Items.description,
				"Contact Name": `${item.Contacts.firstName} ${item.Contacts.lastName}`,
				"Contact Phone Number": item.ContactPhoneNumbers.phoneNumber,
				"Contact Email Address":
					item.ContactEmailAddresses.emailAddress,
				"Contact XID": item.Contacts.c_employeeXid,
			}));

			const headers = Object.keys(formattedData[0]);

			const worksheet = XLSX.utils.json_to_sheet(formattedData, {
				header: headers,
			});

			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
			const buf = XLSX.write(workbook, {
				bookType: "csv",
				type: "buffer",
			});
			const blob = new Blob([buf], { type: "text/csv;charset=utf-8;" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = "data.csv";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("There was an error exporting to CSV", error);
		} finally {
			setIsLoading(false); // Reset loading to false when the operation is complete
		}
	}, [data]);

	return [data, setData, exportToCSV, isLoading];
}

export default useExportableData;
